<div  [style.height.%]="100"
      kendoTooltip
      [tooltipTemplate]="tooltipTemplate"
      position="top"
      title=""
      showOn="none"
      filter="td"
>
  <kendo-grid class="staff-unit-grid"
              [kendoGridBinding]="dataSource.dataSource.data$ | async"
              [pageable]="false"
              [groupable]="false"
              [reorderable]="false"
              [resizable]="true"
              [style.height.%]="100"

              [kendoGridSelectBy]="selection?.forDirective?.selectionKey"
              (selectedKeysChange)="selection?.forDirective?.onSelectedKeysChange($event)"
              [selectedKeys]="selection?.forDirective?.selectedKeys"

              KendoGridExpandedSaveSort
              [sortStorageOptions]="sortStorageOptions"
              [sortable]="{
                allowUnsort: true,
                mode: 'single'
              }"
              [sort]="gridState.sort"
              scrollable="true"
              KendoGridExpanded
              (dbCellClick)="onDblCellClick($event)"
              (cellClick)="onCellClick(rowContextMenu, $event)"
              tableTitleTextWrap="true"
              (cellMouseOver)="onCellMouseOver($event)"
              (cellMouseOut)="onCellMouseOut($event)"
              columnsPadding="6px 5px"
              headerColumnsPadding="5px"
  >
    <ng-template kendoGridToolbarTemplate>
      <div class="grid-header-content">
        <div class="grid-crud staff-units-crud-buttons-area">
          <button (click)="onClickAdd()"
                  [disabled]="!(this.dataSource.paramsDataSource.data$ | async)?.positionId"
                  kendoButton
                  size="small"
                  icon="plus"
                  [primary]="true"
          >
            Добавить
          </button>
          <button (click)="onClickDelete()"
                  [disabled]="!(this.selection.selectedIds.data$ | async)?.length"
                  kendoButton
                  size="small"
                  icon="delete"
                  [primary]="true"
          >
            Удалить
          </button>
          <button (click)="onClickTransfer()"
                  [disabled]="!(transferButtonEnable$ | async)"
                  kendoButton
                  size="small"
                  icon="user"
                  [primary]="true"
          >
            Перевод
          </button>
          <button (click)="onClickDismiss()"
                  [disabled]="!(isCanDismiss$ | async)"
                  kendoButton
                  size="small"
                  icon="cancel-outline"
                  [primary]="true"
          >
            Уволить
          </button>
        </div>
        <kendo-textbox  class="search-input"
                        size="small"
                        placeholder="Поиск"
                        [(value)]="searchValue"
                        (afterValueChanged)="onFilter()"
                        [clearButton]="true"
        ></kendo-textbox>
      </div>
    </ng-template>

    <kendo-grid-column  [field]="this.fieldsNames.code.toString()"
                        title="Табельный номер"
                        [width]="150"
    ></kendo-grid-column>
    <kendo-grid-column [field]="this.fieldsNames.startDate.toString()" title="Дата начала" [width]="110">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.startDate | kendoDate: "dd.MM.yyyy" }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [field]="this.fieldsNames.endDate.toString()" title="Дата окончания" [width]="130">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.endDate | kendoDate: "dd.MM.yyyy" }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [field]="this.fieldsNames.executionDutiesFlag.toString()" title="ИО" [width]="50">
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container *ngIf="dataItem.executionDutiesFlag === true">Да</ng-container>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [field]="this.fieldsNames.fio.toString()" title="ФИО"></kendo-grid-column>
    <kendo-grid-column  [field]="this.fieldsNames.rate.toString()"
                        title="Количество ставок"
                        [width]="100"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{
          dataItem.rate
            | kendoNumber
              : { minimumFractionDigits: 1, maximumFractionDigits: 2 }
        }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column  title="Вид занятости"
                        field="typeOfEmployment"
                        [width]="80"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <img
          *ngIf="getStaffUnitTypeImage(dataItem.typeId)"
          class="type-of-employment-img"
          [src]="getStaffUnitTypeImage(dataItem.typeId)"
          [alt]="dataItem.typeDescription"
        />
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column  [field]="this.fieldsNames.financingSourceShortName.toString()"
                        title="Источник финансирования"
                        [width]="120"
    ></kendo-grid-column>

    <kendo-grid-column  [field]="this.fieldsNames.milk.toString()"
                        title="Молоко"
                        [width]="85"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container *ngIf="dataItem.milk === true">Да</ng-container>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column  field="comment"
                        title="Комментарий"
                        class="comment-column"
    ></kendo-grid-column>


  </kendo-grid>
</div>

<ng-template #tooltipTemplate>
  <div style="display: flex; flex-direction: column; align-items: center">
    <div style="text-align: center; font-size: 16px">
      {{ dataForTooltip?.typeDescription }}
    </div>
  </div>
</ng-template>

<kendo-contextmenu #rowContextMenu
                   [items]="contextMenuItems"
                   (select)="$event?.item?.select()">
</kendo-contextmenu>
