import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { traceClass } from 'src/app/modules/trace/decorators/class.decorator';
import { TracerServiceBase } from 'src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { traceFunc } from 'src/app/modules/trace/decorators/func.decorator';
import { versioningAndNotVersioningDirectoryServiceProvider } from 'src/app/components/directory-edit/services/providers/versioning-and-not-versioning-directory-service-provider';
import { EmployeesGridComponentService } from './services/employees-grid-component.service';
import { CellClickEvent, DataBindingDirective } from '@progress/kendo-angular-grid';
import { EmployeesGridComponentDataSourceServiceBase } from './services/employee-grid-component-data-source.service';
import { xnameofPath } from '../../../../../../../src/app/functions/nameof';
import { KendoGridArrayDataSourceSelection } from '../../../../../../../src/app/classes/array-data-sources/selections/kendo-grid-array-data-source-selection';
import { EmployeeItem } from '../../../../../../../src/app/services/webApi/webApi1/controllers/api1-employers-grid-control-controller.service';
import { ImportFromExcelDialog_ShowDialogParams, ImportFromExcelDialogService } from '../../../../../../../src/app/components/import-from-excel/dialog/import-from-excel-dialog.service';
import { trace } from '../../../../../../../src/app/modules/trace/operators/trace';
import { ReplaySubject, take, takeUntil } from 'rxjs';
import { DirectoryEditService } from '../../../../../../../src/app/components/directory-edit/services/directory-edit.service';
import { DirectoryEditNotVersioningService } from '../../../../../../../src/app/components/directory-edit/services/directory-edit-not-versioning.service';
import { NotVersioningField } from '../../../../../../../src/app/components/directory-edit/services/classes/fields/not-versioning-field';
import { MaskedTextBoxFieldControl } from '../../../../../../../src/app/components/directory-edit/services/classes/field-controls/maskedtextbox-field-control';
import { TextBoxFieldControl } from '../../../../../../../src/app/components/directory-edit/services/classes/field-controls/text-box-field-control';
import { DirectoryEditRef } from '../../../../../../../src/app/components/directory-edit/services/ref-services/directory-edit-ref';
import { FieldType } from '../../../../../../../src/app/components/directory-edit/services/classes/fields/field-type';
import { VersioningField } from '../../../../../../../src/app/components/directory-edit/services/classes/fields/versioning-field';
import { TextAreaFieldControl } from '../../../../../../../src/app/components/directory-edit/services/classes/field-controls/text-area-field-control';
import { DateFieldControl } from '../../../../../../../src/app/components/directory-edit/services/classes/field-controls/date-field-control';
import { DateHelper } from '../../../../../../../src/app/helpers/dateHelper';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { TraceParamEnum } from '../../../../../../../src/app/modules/trace/decorators/classes/traceSetting.interface';
import { traceParam } from '../../../../../../../src/app/modules/trace/decorators/param.decorator';
import { NoVersioningSavedData } from '../../../../../../../src/app/components/directory-edit/services/classes/no-versioning-saved-data';
import { HierarchiStringsConflictsDialogService } from 'src/app/components/hierarchi-strings-conflicts/services/hierarchi-strings-conflicts-dialog.service';
import { CellClickExpandedEvent } from 'src/app/services/directives/grid-treelist-expanded-directive.service';
import { SupportedActionEnum } from 'src/app/classes/domain/enums/supported-action-enum';
import { DropDownListFieldControl } from '../../../../../../../src/app/components/directory-edit/services/classes/field-controls/dropdownlist-field-control';
import { DateOnlyFieldControl } from '../../../../../../../src/app/components/directory-edit/services/classes/field-controls/date-only-field-control';

@Component({
  selector: 'app-employees-grid',
  templateUrl: './employees-grid.component.html',
  styleUrls: ['./employees-grid.component.css'],
  providers: [versioningAndNotVersioningDirectoryServiceProvider, EmployeesGridComponentService, HierarchiStringsConflictsDialogService],
})
@traceClass('EmployeesGridComponent')
export class EmployeesGridComponent implements OnInit, OnDestroy {

  public expandedDetailKeys: any[] = [];
  public expandDetailsBy = (dataItem: EmployeeDirectoryItem): any => dataItem.id;
  /** Строка поиска (фильтрации) */
  public searchValue: string = '';
  /** Директива для доступа и работе с данными грида*/
  @ViewChild(DataBindingDirective) public dataBindingDirective: DataBindingDirective;
  /** Ссылка на шаблон настроек импорта */
  @ViewChild('importSettingsTemplate') public importSettingsTemplateRef: TemplateRef<any>;

  /** Ключи названий всех колонок */
  public fieldsNames = xnameofPath(EmployeeDirectoryItem, '');

  private streams$ = { unsubscribes: new ReplaySubject<any>(1) };
  private innField = new NotVersioningField(this.fieldsNames.innString.toString(), { isAllowNull: false, title: 'ИНН' }, new MaskedTextBoxFieldControl({ mask: '000000000000' }));
  private snilsField = new NotVersioningField(this.fieldsNames.snilsString.toString(), { isAllowNull: false, title: 'СНИЛС' }, new MaskedTextBoxFieldControl({ mask: '000-000-000-00' }));
  private phoneField = new NotVersioningField(this.fieldsNames.phoneString.toString(), { isAllowNull: false, title: 'Номер телефона' }, new MaskedTextBoxFieldControl({ mask: '+7 (000) 000-00-00' }));
  private addressField = new NotVersioningField(this.fieldsNames.addressString.toString(), { isAllowNull: false, title: 'Место жительства' }, new TextBoxFieldControl({minLength: 1 }));
  private directoryEditServiceRef: DirectoryEditRef;
  /** Настройки полей для редактирования в справочнике */
  private fields: Array<FieldType> = [
    new VersioningField(this.fieldsNames.firstName.toString(), SupportedActionEnum.stafflist_employee_first_name, { isAllowNull: false, title: 'Имя' }, new TextBoxFieldControl({ minLength: 2, maxLength: 30, autoTrimValue: true, autoCapitalizeValue: true })),
    new VersioningField(this.fieldsNames.code.toString(), SupportedActionEnum.stafflist_employee_code, { isAllowNull: false, title: 'Табельный номер' }, new TextBoxFieldControl({ minLength: 1, maxLength: 20, autoTrimValue: true })),
    new VersioningField(this.fieldsNames.lastName.toString(), SupportedActionEnum.stafflist_employee_last_name, { isAllowNull: false, title: 'Фамилия' }, new TextBoxFieldControl({ minLength: 2, maxLength: 30, autoTrimValue: true, autoCapitalizeValue: true })),
    new VersioningField(this.fieldsNames.patronymic.toString(), SupportedActionEnum.stafflist_employee_patronymic, { isAllowNull: true, title: 'Отчество' }, new TextBoxFieldControl({ maxLength: 30, autoTrimValue: true, autoCapitalizeValue: true })),
    new NotVersioningField(this.fieldsNames.comment.toString(), { isAllowNull: true, title: 'Комментарий' }, new TextAreaFieldControl({ maxLength: 100, autoTrimValue: true })),
    new NotVersioningField(this.fieldsNames.startDate.toString(), { isAllowNull: true, title: 'Дата начала' }, new DateFieldControl({ maxValue: () => this.directoryEditServiceRef.currentDataItem.endDate })),
    new NotVersioningField(this.fieldsNames.endDate.toString(), { isAllowNull: true, title: 'Дата начала' }, new DateFieldControl({ minValue: () => this.directoryEditServiceRef.currentDataItem.startDate })),

    new NotVersioningField(this.fieldsNames.gender.toString(), { isAllowNull: false, title: 'Пол' }, new DropDownListFieldControl({ data: [{ id: 0, text: 'муж.' }, { id: 1, text: 'жен.' }] })),
    new NotVersioningField(this.fieldsNames.birthday.toString(), { isAllowNull: false, title: 'Дата рождения' }, new DateOnlyFieldControl()),
  ];

  private _dataSourceService: EmployeesGridComponentDataSourceServiceBase;

  @Input()
  public get dataSourceService(): EmployeesGridComponentDataSourceServiceBase {
    return this._dataSourceService;
  }

  public set dataSourceService(value: EmployeesGridComponentDataSourceServiceBase) {
    if (this._dataSourceService) {
      throw Error('Повторная установка dataSource не поддерживается!');
    }
    this._dataSourceService = value;
  }

  private _selection: KendoGridArrayDataSourceSelection<EmployeeDirectoryItem, number>;

  @Input()
  public get selection(): KendoGridArrayDataSourceSelection<EmployeeDirectoryItem, number> {
    return this._selection;
  }

  public set selection(value: KendoGridArrayDataSourceSelection<EmployeeDirectoryItem, number>) {
    if (this._selection) {
      throw Error('Повторная установка selection не поддерживается!');
    }
    this._selection = value;
  }

  public get currentSelectedItem(): EmployeeDirectoryItem {
    return this.selection.selectedItems.data[0];
  }

  constructor(private readonly service: EmployeesGridComponentService,
              private readonly directoryEditService: DirectoryEditService,
              private readonly directoryEditNotVersioningService: DirectoryEditNotVersioningService,
              private readonly hscDialogService: HierarchiStringsConflictsDialogService,
              private readonly importFromExcelDialogService: ImportFromExcelDialogService,
              private readonly traceService: TracerServiceBase) {
  }

  @traceFunc()
  ngOnInit() {
    this.initDirectoryEditService();
  }

  /** Инициализация сервиса редактирования */
  public initDirectoryEditService() {
    this.directoryEditServiceRef = this.directoryEditService.init({
      addVersion$: (actionId, versioningEntity) => this.dataSourceService.addEmployeeVersion$(actionId, versioningEntity),
      deleteVersion$: (versionId) => this.dataSourceService.deleteVersion$(versionId),
      getVersionsByOwnerIdAndActionId$: (ownerId, actionId) => this.dataSourceService.getVersionsByOwnerIdAndActionId$(ownerId, actionId),
      editVersion$: (actionId, entity) => this.dataSourceService.editEmployeeVersion$(actionId, entity),
      saveNoVersioning$: (entity:any, field: NotVersioningField) => {
        if(field.name === this.fieldsNames.endDate.toString()){
          return this.hscDialogService.show2$((isSafe) => this.dataSourceService.editEndDateEmployee$(entity, isSafe));
        }
        else
        {
          return this.dataSourceService.saveEmployee$(entity);
        }
      },

      isNeedHideData: (entity: EmployeeItem) => !DateHelper.isDateIntervalsIntersect(entity.startDate, entity.endDate, this.dataSourceService.paramsDataSource.data.reportRangeStartDate, this.dataSourceService.paramsDataSource.data.reportRangeEndDate),
    }, this.fields);

    this.directoryEditNotVersioningService.onSave$.subscribe(result => this.onFieldEditNoVersioning(result));

  }

  /** Начало редактирования колонки */
  @traceFunc()
  public edit(fieldName: EmployeeDirectoryItemType, dataItem: any, elementRef: any) {
    switch (fieldName) {
      case this.fieldsNames.passportsString.toString():
        this.service.startEditPassportData(dataItem, this);
        break;
      case this.fieldsNames.addressString.toString():
        this.startEditAddressData(dataItem, elementRef);
        break;
      case this.fieldsNames.innString.toString():
        this.startEditInnData(dataItem, elementRef);
        break;
      case this.fieldsNames.snilsString.toString():
        this.startEditSnilsData(dataItem, elementRef);
        break;
      case this.fieldsNames.phoneString.toString():
        this.startEditPhoneData(dataItem, elementRef);
        break;

      default:
        this.directoryEditServiceRef.edit(fieldName, dataItem, elementRef);
    }
  }

  /** Сохранение кастомных контролов */
  public onFieldEditNoVersioning(data: NoVersioningSavedData) {
    if (data.field === this.innField) {
      this.service.onInnDataSaved(data.dataItem, data.value, this);
    } else if (data.field === this.snilsField) {
      this.service.onSnilsDataSaved(data.dataItem, data.value, this);
    } else if (data.field === this.phoneField) {
      this.service.onPhoneDataSaved(data.dataItem, data.value, this);
    } else if (data.field === this.addressField) {
      this.service.onAddressDataSaved(data.dataItem, data.value, this);
    }
  }

  @traceFunc({ traceParamType: TraceParamEnum.traceByDecorators })
  public startEditAddressData(@traceParam({ maxLength: 500 }) dataItem: EmployeeItem, elementRef: any) {
    this.directoryEditNotVersioningService.edit(dataItem, dataItem.address?.data?.address, this.addressField, elementRef);
  }

  @traceFunc({ traceParamType: TraceParamEnum.traceByDecorators })
  public startEditInnData(@traceParam({ maxLength: 500 }) dataItem: EmployeeItem, elementRef: any) {
    this.directoryEditNotVersioningService.edit(dataItem, dataItem.inn?.data?.inn, this.innField, elementRef);
  }

  @traceFunc({ traceParamType: TraceParamEnum.traceByDecorators })
  public startEditSnilsData(@traceParam({ maxLength: 500 }) dataItem: EmployeeItem, elementRef: any) {
    this.directoryEditNotVersioningService.edit(dataItem, dataItem.snils?.data?.snils, this.snilsField, elementRef);
  }

  @traceFunc({ traceParamType: TraceParamEnum.traceByDecorators })
  public startEditPhoneData(@traceParam({ maxLength: 500 }) dataItem: EmployeeItem, elementRef: any) {
    this.directoryEditNotVersioningService.edit(dataItem, dataItem.phone?.data?.mobilePhone, this.phoneField, elementRef);
  }

  /** Событие происходит при начале фильтрации */
  @traceFunc()
  public onFilter() {
    const inputValue = this.searchValue;
    this.traceService.add2('Фильтрация', { obj: inputValue });

    const filter = <CompositeFilterDescriptor>{
      logic: 'or',
      filters: [
        {
          field: this.fieldsNames.firstName.toString(),
          operator: 'contains',
          value: inputValue,
        },
        {
          field: this.fieldsNames.lastName.toString(),
          operator: 'contains',
          value: inputValue,
        },
        {
          field: this.fieldsNames.patronymic.toString(),
          operator: 'contains',
          value: inputValue,
        },
        {
          field: this.fieldsNames.code.toString(),
          operator: 'contains',
          value: inputValue,
        },
        {
          field: this.fieldsNames.comment.toString(),
          operator: 'contains',
          value: inputValue,
        },
      ],
    };
    this.dataBindingDirective.skip = 0;
    this.dataBindingDirective.filter = filter;
    this.dataBindingDirective.rebind();
  }

  /** Событие происходит при клике на кнопку добавления нового элемента нового элемента в справочник */
  @traceFunc()
  public onClickAdd() {
    this.service.showAddDialog(this);
  }

  /** Событие происходит при клике на кнопку удалить */
  @traceFunc()
  public onClickDelete() {
    this.service.showDeleteDialog(this);
  }

  /** Событие происходит при двойном клике на ячейку */
  @traceFunc()
  public onDblCellClick(e: CellClickExpandedEvent<CellClickEvent>) {
    if (!e.originalEvent.isEdited) {
      this.edit(e.originalEvent.column.field, e.originalEvent.dataItem, e.originalEvent.originalEvent.target);
    }
  }

  /** Событие происходит при клике на импорт дополнительных данных */
  @traceFunc()
  public onClickImportExtendedData() {
    const dialog = this.importFromExcelDialogService.show(new ImportFromExcelDialog_ShowDialogParams(this.dataSourceService.getDownloadTemplateUrl(), this.dataSourceService.getUploadFileUrl(), this.importSettingsTemplateRef));
    dialog.importResult$
      .pipe(trace(this.traceService), take(1), takeUntil(this.streams$.unsubscribes))
      .subscribe(s => {
        if (s.isSuccess) {
          this.dataSourceService.updateData$();
        }
      });
  }

  public ngOnDestroy() {
    this.streams$.unsubscribes.next(null);
    this.streams$.unsubscribes.complete();
    this.directoryEditServiceRef.close();
  }
}

export class EmployeeDirectoryItem extends EmployeeItem {
  public state: string;
  public passportsString: string;
  public addressString: string;
  public innString: string;
  public snilsString: string;
  public phoneString: string;
  public fullName: string;
}

export type EmployeeDirectoryItemType = keyof EmployeeDirectoryItem;
