import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {map, Observable} from 'rxjs';
import { ReportDatesComponentSharedService } from 'src/app/components/report-dates/services/report-dates-component-shared.service';
import { traceClass } from 'src/app/modules/trace/decorators/class.decorator';
import { traceFunc } from 'src/app/modules/trace/decorators/func.decorator';
import { TracerServiceBase } from 'src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import {
  SubdivisionsTreelistComponentDataSourceService
} from "../../../../../../src/app/components/subdivisions/subdivisions-treelist/services/subdivisions-treelist-component-data.service";
import {exErrorHandler} from "../../../../../../src/app/operators/ex-error-handler";
import {
  DisplayErrorsService
} from "../../../../../../src/app/components/display-errors/services/display-errors.service";
import {
  KendoTreeListDataSourceSelection
} from "../../../../../../src/app/classes/array-data-sources/selections/kendo-treelist-array-data-source-selection";
import {ISubdivision} from "../../../../../../src/app/classes/domain/POCOs/stafflist/Subdivision";
import {exElementByIndexOr} from "../../../../../../src/app/operators/ex-element-by-index-or";

@Component({
  selector: 'app-subdivisions-list',
  templateUrl: './subdivisions-list.component.html',
  styleUrls: ['./subdivisions-list.component.css'],
  providers: [SubdivisionsTreelistComponentDataSourceService]
})
@traceClass('SubdivisionsListComponent')
export class SubdivisionsListComponent implements OnInit, OnDestroy {

  /** Выбранный идентификатор подразделения */
  @Input() public set subdivisionId(value: number){
    this.selection.setIds(!value ? [] : [value]);
  }

  /** Событие выбора */
  @Output() public onSelected: EventEmitter<ISubdivision> = new EventEmitter<ISubdivision>();

  /** Выделенные элементы */
  public readonly selection: KendoTreeListDataSourceSelection<ISubdivision, number>;

  constructor(
    private reportDatesComponentSharedService: ReportDatesComponentSharedService,
    public dataSourceService: SubdivisionsTreelistComponentDataSourceService,
    public displayErrorService: DisplayErrorsService,
    private tracerService: TracerServiceBase
  ) {
    this.selection = new KendoTreeListDataSourceSelection<ISubdivision, number>(dataSourceService.dataSource);
    this.subscribeToSelectionChange(this.selection.selectedItems.data$);
  }

  @traceFunc()
  ngOnInit() {
    this.dataSourceService.reloadData$(
      this.reportDatesComponentSharedService.reportDateValues$
        .pipe(
          map(value => {
            return {
              forDate: value.reportDate ?? new Date(),
              startDate: value.reportRangeStartDate,
              endDate: value.reportRangeEndDate
            }
          }),
          exErrorHandler(this.displayErrorService)
        )
    ).subscribe();
  }

  /** Подписаться на изменения выделенных элементов */
  private subscribeToSelectionChange(selectionChange$: Observable<ISubdivision[]>){
    selectionChange$.pipe(exElementByIndexOr(0, undefined)).subscribe(value => {
      this.onSelected.emit(value);
    })
  }

  @traceFunc()
  ngOnDestroy() {
  }
}
