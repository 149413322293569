import {Injectable} from "@angular/core";
import { Observable } from 'rxjs';
import {WebApi1Service} from "../web-api1.service";
import {HttpClient} from "@angular/common/http";
import {IStaffUnit, IStaffUnitOptional, StaffUnit} from "../../../../classes/domain/POCOs/stafflist/StaffUnit";
import { HttpParamsHelper } from "src/app/helpers/httpParamsHelper";
import { VersioningItem } from "src/app/classes/directories/edit/versioningItem";
import {EditEndDateDto, IdAndRowVersionType} from "./shared-DTOs/edit-end-date-dto";
import { SupportedActionEnum } from "src/app/classes/domain/enums/supported-action-enum";

/** Сервис работы с контроллером StaffUnit */
@Injectable({
  providedIn: "root"
})

export class Api1StaffUnitControllerService {

  constructor(  private webApi1Service: WebApi1Service,
                private httpClient: HttpClient) {
  }

  public addStaffUnit$(entity: IStaffUnitOptional): Observable<StaffUnit>
  {
    return this.httpClient
    .post<StaffUnit>(this.webApi1Service.controllers.staffUnit.actions.add.toString(),
      entity)
  }

  /** Сохранить StaffUnit */
  public save$(entity:StaffUnit): Observable<[]>{
    return this.httpClient
      .post<[]>(this.webApi1Service.controllers.staffUnit.actions.save.toString(),
        entity
      );
  }

  public getVersionsByOwnerIdAndActionId$(ownerId: number, actionId: SupportedActionEnum): Observable<Array<VersioningItem>>
  {
    return this.httpClient
    .get<Array<VersioningItem>>(this.webApi1Service.controllers.staffUnit.actions.getVersionsByOwnerIdAndActionId.toString(),
      {
        params: HttpParamsHelper.getParams([['ownerId', ownerId], ['actionId', actionId]])
      })
  }

  public addStaffUnitVersion$(actionId: SupportedActionEnum, versioningEntity: VersioningItem): Observable<VersioningItem>
  {
    return this.httpClient
    .post<VersioningItem>(this.webApi1Service.controllers.staffUnit.actions.addVersion.toString(),
      {
        OwnerId:versioningEntity.ownerId,
        ActionId:actionId,
        Data: versioningEntity
      })
  }

  public editStaffUnitVersion$(actionId: SupportedActionEnum, versioningEntity: VersioningItem): Observable<VersioningItem>
  {
    return this.httpClient
    .post<VersioningItem>(this.webApi1Service.controllers.staffUnit.actions.editVersion.toString(),
      {
        OwnerId:versioningEntity.ownerId,
        ActionId:actionId,
        Data: versioningEntity
      })
  }

  public deleteStaffUnitVersion$(versionId: number) : Observable<boolean>{
    return this.httpClient.delete<boolean>(this.webApi1Service.controllers.staffUnit.actions.deleteVersion.toString(),
      {params: HttpParamsHelper.getParams([["versionId",versionId]])}
      )
  }

  /** Удалить */
  public delete$(entityId:number, timestamp:[]): Observable<boolean>{
    return this.httpClient.post<boolean>(this.webApi1Service.controllers.staffUnit.actions.delete.toString(),
      {
        Id: entityId,
        timestamp: timestamp
      }
    )
  }

  /** Редактировать окончание срока действия */
  public editEndDate$(date: Date, rows: IdAndRowVersionType[], safe: boolean): Observable<boolean>{
    return this.httpClient.post<boolean>(this.webApi1Service.controllers.staffUnit.actions.editEndDate.toString(),
      new EditEndDateDto(date, rows.map(x => { return {id: x.id, timestamp: x.timestamp}}), safe));
  }

  /** Редактировать начало срока действия */
  public editStartDate$(date: Date, rows: IdAndRowVersionType[], safe: boolean): Observable<boolean>{
    return this.httpClient.post<boolean>(this.webApi1Service.controllers.staffUnit.actions.editStartDate.toString(),
      new EditEndDateDto(date, rows.map(x => { return {id: x.id, timestamp: x.timestamp}}), safe));
  }

  /** Редактировать окончание срока действия */
  public transfer$(date: Date, rows: IdAndRowVersionType[], safe: boolean, positionId: number, financingSourceId: number, comment: string): Observable<IStaffUnit>{
    return this.httpClient.post<IStaffUnit>(this.webApi1Service.controllers.staffUnit.actions.transfer.toString(),
      new TransferDto(date, rows.map(x => { return {id: x.id, timestamp: x.timestamp}}), safe, positionId, financingSourceId, comment));
  }
}

class TransferDto implements EditEndDateDto{
  constructor(public date: Date,
              public rows: IdAndRowVersionType[],
              public safe: boolean,
              public positionId: number,
              public financingSourceId: number,
              public comment: string) {
  }
}
