<form class="k-form" [formGroup]="form" *ngIf="form">
  <div class="form-inline-div">
    <kendo-formfield>
      <kendo-label [for]="startDate" text="Дата начала"></kendo-label>
      <kendo-datepicker #startDate [formControlName]="'startDate'" [max]="form.controls?.endDate?.value > form.controls?.startDate?.value ? form.controls?.endDate?.value : null"></kendo-datepicker>
      <kendo-formerror *ngIf="form.controls.startDate.errors?.required">Пожалуйста, заполните обязательное поле.</kendo-formerror>
      <kendo-formerror *ngIf="form.controls.startDate.errors?.startDateGreaterThanEndDate">Дата начала действия не может быть позже чем дата окончания действия</kendo-formerror>
      <kendo-formerror *ngIf="form.controls.startDate.errors?.startDateLowerThanParent">Дата начала действия не может быть раньше чем дата начала действия родителя. Значение родителя: {{form.controls.startDate.errors?.parentValue | kendoDate: 'dd.MM.yyyy'}}</kendo-formerror>
    </kendo-formfield>

    <kendo-formfield>
      <kendo-label [for]="endDate" text="Дата окончания"></kendo-label>
      <kendo-datepicker #endDate [formControlName]="'endDate'" [min]="form.controls?.startDate?.value < form.controls?.endDate?.value ? form.controls?.startDate?.value : null"></kendo-datepicker>
      <kendo-formerror *ngIf="form.controls.endDate.errors?.required">Пожалуйста, заполните обязательное поле.</kendo-formerror>
      <kendo-formerror *ngIf="form.controls.endDate.errors?.endDateLowerThanStartDate">Дата окончания действия не может быть раньше чем дата начала действия</kendo-formerror>
      <kendo-formerror *ngIf="form.controls.endDate.errors?.endDateGreaterThanParent">Дата окончания действия не может быть позже чем дата окончания действия родителя. Значение родителя: {{form.controls.endDate.errors?.parentValue | kendoDate: 'dd.MM.yyyy'}}</kendo-formerror>
    </kendo-formfield>
  </div>
  <div>
    <kendo-formfield *ngIf="!form.value.isAutogenerateCode">
      <kendo-label [for]="code" text="Табельный номер"></kendo-label>
      <input kendoTextBox inputTrim #code [formControlName]="'code'" />
      <kendo-formerror *ngIf="form.controls.code.errors?.required">Пожалуйста, заполните обязательное поле.</kendo-formerror>
      <kendo-formerror *ngIf="form.controls.code.errors?.minlength">Длина значения должна быть больше {{form.controls.code.errors?.minlength.requiredLength}}. Текущая длина: {{form.controls.code.errors?.minlength.actualLength}}</kendo-formerror>
      <kendo-formerror *ngIf="form.controls.code.errors?.maxlength">Длина значения должна быть меньше {{form.controls.code.errors?.maxlength.requiredLength}}. Текущая длина: {{form.controls.code.errors?.maxlength.actualLength}}</kendo-formerror>
    </kendo-formfield>

    <kendo-formfield class="horizontal-form-field">
      <kendo-label [for]="isAutogenerateCode" text="Генерировать табельный номер автоматически:"></kendo-label>
      <input type="checkbox" kendoCheckBox #isAutogenerateCode [formControlName]="'isAutogenerateCode'" (change)="form.controls.code.updateValueAndValidity();"/>
    </kendo-formfield>
  </div>

  <div class="form-inline-fio-div">
    <kendo-formfield>
      <kendo-label [for]="lastName" text="Фамилия"></kendo-label>
      <input kendoTextBox inputTrim inputCapitalize #lastName [formControlName]="'lastName'" />
      <kendo-formerror *ngIf="form.controls.lastName.errors?.required">Пожалуйста, заполните обязательное поле.</kendo-formerror>
      <kendo-formerror *ngIf="form.controls.lastName.errors?.minlength">Длина значения должна быть больше {{form.controls.lastName.errors?.minlength.requiredLength}}. Текущая длина: {{form.controls.lastName.errors?.minlength.actualLength}}</kendo-formerror>
      <kendo-formerror *ngIf="form.controls.lastName.errors?.maxlength">Длина значения должна быть меньше {{form.controls.lastName.errors?.maxlength.requiredLength}}. Текущая длина: {{form.controls.lastName.errors?.maxlength.actualLength}}</kendo-formerror>
    </kendo-formfield>

    <kendo-formfield>
      <kendo-label [for]="firstName" text="Имя"></kendo-label>
      <input kendoTextBox inputTrim inputCapitalize #firstName [formControlName]="'firstName'" />
      <kendo-formerror *ngIf="form.controls.firstName.errors?.required">Пожалуйста, заполните обязательное поле.</kendo-formerror>
      <kendo-formerror *ngIf="form.controls.firstName.errors?.minlength">Длина значения должна быть больше {{form.controls.firstName.errors?.minlength.requiredLength}}. Текущая длина: {{form.controls.firstName.errors?.minlength.actualLength}}</kendo-formerror>
      <kendo-formerror *ngIf="form.controls.firstName.errors?.maxlength">Длина значения должна быть меньше {{form.controls.firstName.errors?.maxlength.requiredLength}}. Текущая длина: {{form.controls.firstName.errors?.maxlength.actualLength}}</kendo-formerror>
    </kendo-formfield>

    <kendo-formfield>
      <kendo-label [for]="patronymic" text="Отчество"></kendo-label>

      <input kendoTextBox inputTrim inputCapitalize #patronymic [formControlName]="'patronymic'" />
      <kendo-formerror *ngIf="form.controls.patronymic.errors?.minlength">Длина значения должна быть больше {{form.controls.patronymic.errors?.minlength.requiredLength}}. Текущая длина: {{form.controls.patronymic.errors?.minlength.actualLength}}</kendo-formerror>
      <kendo-formerror *ngIf="form.controls.patronymic.errors?.maxlength">Длина значения должна быть меньше {{form.controls.patronymic.errors?.maxlength.requiredLength}}. Текущая длина: {{form.controls.patronymic.errors?.maxlength.actualLength}}</kendo-formerror>
    </kendo-formfield>
  </div>


  <kendo-formfield>
    <kendo-label [for]="declensions" text="Склонения по падежам ФИО"></kendo-label>
    <app-employee-names-declensions-control #declensions formControlName="declensions"></app-employee-names-declensions-control>
  </kendo-formfield>

  <div class="form-inline-div">
    <kendo-formfield>
      <kendo-label text="Пол"></kendo-label>
      <kendo-dropdownlist [formControl]="form.controls.gender" [data]="[{ id: 0, text: 'муж.' }, { id: 1, text: 'жен.' }]" [valuePrimitive]="true" valueField="id" textField="text"></kendo-dropdownlist>
      <kendo-formerror *ngIf="form.controls.gender.errors?.required">Пожалуйста, заполните обязательное поле.</kendo-formerror>
    </kendo-formfield>

    <kendo-formfield>
      <kendo-label text="Дата рождения"></kendo-label>
      <kendo-datepicker dateOnlyFromControlToDate [formControl]="form.controls.birthday"></kendo-datepicker>
      <kendo-formerror *ngIf="form.controls.birthday.errors?.required">Пожалуйста, заполните обязательное поле.</kendo-formerror>
    </kendo-formfield>
  </div>

  <kendo-formfield>
    <kendo-label [for]="comment" text="Комментарий"></kendo-label>
    <input kendoTextBox #comment [formControlName]="'comment'" />
    <kendo-formerror *ngIf="form.controls.comment.errors?.minlength">Длина значения должна быть больше {{form.controls.comment.errors?.minlength.requiredLength}}. Текущая длина: {{form.controls.comment.errors?.minlength.actualLength}}</kendo-formerror>
    <kendo-formerror *ngIf="form.controls.comment.errors?.maxlength">Длина значения должна быть меньше {{form.controls.comment.errors?.maxlength.requiredLength}}. Текущая длина: {{form.controls.comment.errors?.maxlength.actualLength}}</kendo-formerror>
  </kendo-formfield>

  <div class="k-form-buttons">
    <button kendoButton type="button" (click)="onClickCancel()">Отменить</button>
    <button kendoButton type="button" primary="true" [disabled]="!form.valid" (click)="onClickSave()">Сохранить</button>
  </div>
</form>

