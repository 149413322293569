import {  Observable } from "rxjs";
import { ArrayDataSourceIEntityId, DataSource } from "src/app/classes/array-data-sources/data-source";
import { IStaffUnitType } from "src/app/classes/domain/POCOs/stafflist/StaffUnitType";
import { ArrayDataSourceIEntityIdServiceWithParamsBase } from "../data-source-services/data-source.service";

export class StaffUnitTypeDataSourceService
extends ArrayDataSourceIEntityIdServiceWithParamsBase<any, IStaffUnitType>{

  readonly paramsDataSource = new DataSource<any>();
  readonly dataSource = new ArrayDataSourceIEntityId<IStaffUnitType>();

  constructor(private readonly parentDataSourceService: IStaffUnitTypeDataSourceService_ParentDataSourceService) {
    super();
  }

  protected useSignalR$(): Observable<Observable<any>> | null {
    //Это енам, он не меняется, поэтому signalr нет
   return null;
  }

  protected _reloadData$(params: any): Observable<IStaffUnitType[]> {
    return this._reloadFromRemoteByIds$(params);
  }

  protected _reloadFromRemoteByIds$(params: any): Observable<IStaffUnitType[]> {
    return this.parentDataSourceService.getStaffUnitTypes$();
  }
}


export interface IStaffUnitTypeDataSourceService_ParentDataSourceService {
  getStaffUnitTypes$(): Observable<IStaffUnitType[]>;
}
