<app-print-quarter-settings-with-subdivisions #childQuarterSettingsComponent
                                              [checkableSettings] = checkableSettings
                                              [displayTextFn]="displayTextFn"
                                              [expandAllSubdivisions]="expandAllSubdivisions"
                                              [selection]="selection"
                                              [subdivisionDataSourceService]="subdivisionDataSourceService"
                                              (print$)="onPrint($event)"
                                              (cancel$)="onClickCancel()">

  <ng-container row1-label>
    <kendo-label class="k-checkbox-label"
                 style="margin-left: 5px;"
                 [for]="suTypes"
                 text="Вид занятости:">
    </kendo-label>
  </ng-container>
  <ng-container row1-control>
    <kendo-multiselect #suTypes
                       style="margin-left: 5px;"
                       [data]="staffUnitTypes$ | async"
                       textField="description"
                       valueField="id"
                       [checkboxes]="true"
                       [autoClose]="false"
                       [formControl]=staffUnitTypes>
    </kendo-multiselect>
  </ng-container>
</app-print-quarter-settings-with-subdivisions>
