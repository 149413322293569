import {Injectable} from "@angular/core";
import {map, Observable} from "rxjs";
import {ArrayDataSourceIEntityId, DataSource} from "src/app/classes/array-data-sources/data-source";
import {DataHasOwnerStateBuilder} from "src/app/classes/data-state-builders/data-has-owner-state-builder";
import {VersioningItem} from "src/app/classes/directories/edit/versioningItem";
import { SupportedActionEnum } from "src/app/classes/domain/enums/supported-action-enum";
import {Employee, IEmployee} from "src/app/classes/domain/POCOs/stafflist/Employee";
import {IFinancingSource} from "src/app/classes/domain/POCOs/stafflist/FinancingSource";
import {IPosition, Position} from "src/app/classes/domain/POCOs/stafflist/Position";
import {IStaffUnit, IStaffUnitOptional, StaffUnit} from "src/app/classes/domain/POCOs/stafflist/StaffUnit";
import {IStaffUnitType} from "src/app/classes/domain/POCOs/stafflist/StaffUnitType";
import { ISubdivision } from "src/app/classes/domain/POCOs/stafflist/Subdivision";
import {IForDate} from "src/app/classes/for-date";
import { exLoadingMessage } from "src/app/operators/ex-loading-message.operator";
import {
  IFreeRatesDataSourceService_Params
} from "src/app/services/common-data-source-services/free-rates-data-source.service";
import { IPositionWithExtendedDataDataSourceService_Params } from "src/app/services/common-data-source-services/position-with-extended-data-data-source.service";
import {ArrayDataSourceIEntityIdServiceWithParamsBase} from "src/app/services/data-source-services/data-source.service";
import {LoadingIndicatorService} from "src/app/services/loading-indicator.service";
import {DbChangedListener} from "src/app/services/signal-r/listeners/db-changed-listener";
import {
  Api1EmployeeControllerService
} from "src/app/services/webApi/webApi1/controllers/api1-employee-controller.service";
import {
  Api1FinancingSourceControllerService
} from "src/app/services/webApi/webApi1/controllers/api1-financing-source-controller.service";
import {
  Api1PositionControllerService
} from "src/app/services/webApi/webApi1/controllers/api1-position-controller.service";
import {
  Api1StaffListSettingsControllerService
} from "src/app/services/webApi/webApi1/controllers/api1-staff-list-settings-controller.service";
import {
  Api1StaffUnitTypeControllerService
} from "src/app/services/webApi/webApi1/controllers/api1-staff-unit-type-controller.service";
import {
  Api1StaffUnitsControlControllerService,
  FreeRatesDTO,
  PositionWithExtendedData,
  StaffUnitGridItem
} from "src/app/services/webApi/webApi1/controllers/api1-staff-units-control.service";
import {
  Api1StaffUnitControllerService
} from "src/app/services/webApi/webApi1/controllers/api1-staffUnit-controller.service";
import { Api1SubdivisionControllerService } from "src/app/services/webApi/webApi1/controllers/api1-subdivision-controller.service";
import {StaffUnitTypeEnum} from "../../../../../../../../src/app/classes/domain/enums/StaffUnitTypeEnum";

/** Базовый класс сервиса данных */
@Injectable()
export abstract class StaffUnitsGridComponentDataSourceServiceBase extends ArrayDataSourceIEntityIdServiceWithParamsBase<StaffUnitsGridComponentDataSourceService_Params, StaffUnitGridItem>{

  public abstract transferStaffUnit$(staffUnitId: number, staffUnitTimestamp: [], positionId: number, financingSourceId: number, comment: string, date: Date, isSafe: boolean);
  public abstract dismissStaffUnit$(entity: StaffUnitGridItem, date: Date, isSave: boolean);

  public abstract deleteStaffUnit$(entity: Pick<IStaffUnit, 'id' | 'timestamp'>);
  public abstract getEmployeesForDate$(forDate: IForDate, employeeIds: number[]): Observable<IEmployee[]>;
  public abstract addStaffUnit$(entity: IStaffUnitOptional);
  public abstract getStaffUnitTypes$(): Observable<IStaffUnitType[]>;
  public abstract getFreeRates$(params: IFreeRatesDataSourceService_Params): Observable<FreeRatesDTO>;
  public abstract getPositionsForDate$(forDate: IForDate, positionIds: number[]): Observable<IPosition[]>;

  /** Versioning */
  public abstract deleteVersion$(versionId: number): Observable<boolean>;
  public abstract getVersionsByOwnerIdAndActionId$(ownerId: number, actionId: SupportedActionEnum): Observable<Array<VersioningItem>>;
  public abstract editVersion$(actionId: SupportedActionEnum, entity: any): Observable<VersioningItem>;
  public abstract addVersion$(actionId: SupportedActionEnum, versioningEntity: VersioningItem): Observable<VersioningItem>;

  /** NotVersioning */
  public abstract saveNoVersioning$(entity:any): Observable<[]>;
  public abstract editEndDate$(entity: StaffUnitGridItem, isSave: boolean): Observable<boolean>;
  public abstract editStartDate$(entity: StaffUnitGridItem, isSave: boolean): Observable<boolean>;

  public abstract settingsRateStep$(): Observable<number>;

  public abstract getFinancingSources$(financingSourcesIds: number[]): Observable<IFinancingSource[]>;
  public abstract getSubdivisionsForDate$(forDate: IForDate, subdivisionIds: number[]): Observable<ISubdivision[]>;
  public abstract getPositionsWithExtendedDataForDate$(params: IPositionWithExtendedDataDataSourceService_Params): Observable<PositionWithExtendedData[]>;
}

/**
 * Сервис данных.<br>
 * Используются параметры IForDate<br>
 * Используется для получения данных Api1SubdivisionControllerService<br>
 * Используется signalR - изменение подразделений
 */
@Injectable()
export class StaffUnitsGridComponentDataSourceService
  extends ArrayDataSourceIEntityIdServiceWithParamsBase<StaffUnitsGridComponentDataSourceService_Params, StaffUnitGridItem>
  implements StaffUnitsGridComponentDataSourceServiceBase{

  readonly paramsDataSource = new DataSource<StaffUnitsGridComponentDataSourceService_Params>();
  readonly dataSource = new ArrayDataSourceIEntityId<StaffUnitGridItem>();

  private readonly _alloweStaffUnitTypes: StaffUnitTypeEnum[] = [
    StaffUnitTypeEnum.Basic,
    StaffUnitTypeEnum.MoonlighterInner,
    StaffUnitTypeEnum.MoonlighterExternal
  ];

  constructor(
    private readonly api1SubdivisionControllerService: Api1SubdivisionControllerService,
    private readonly api1StaffUnitsControlControllerService: Api1StaffUnitsControlControllerService,
    private readonly api1PositionControllerService: Api1PositionControllerService,
    private readonly api1StaffUnitTypeControllerService: Api1StaffUnitTypeControllerService,
    private readonly api1StaffUnitControllerService: Api1StaffUnitControllerService,
    private readonly api1EmployeeControllerService: Api1EmployeeControllerService,
    private readonly api1FinancingSourceControllerService: Api1FinancingSourceControllerService,
    private readonly settingsService: Api1StaffListSettingsControllerService,
    private readonly loadingIndicatorService: LoadingIndicatorService,
    private readonly dbChangedListener: DbChangedListener
  ) {
    super();
  }

  public transferStaffUnit$(staffUnitId: number, staffUnitTimestamp: [], positionId: number, financingSourceId: number, comment: string, date: Date, isSafe: boolean) {
    return this.api1StaffUnitControllerService.transfer$(date, [{id: staffUnitId, timestamp: staffUnitTimestamp}], isSafe, positionId, financingSourceId, comment ).pipe(exLoadingMessage(this.loadingIndicatorService, 'Перевод исполнения должности'));
  }

  public dismissStaffUnit$(entity: StaffUnitGridItem, date: Date, isSave: boolean) {
    return this.api1EmployeeControllerService.editEndDate$(date, [{id: entity.employeeId, timestamp: entity.employeeTimestamp}], isSave).pipe(exLoadingMessage(this.loadingIndicatorService, 'Увольнение сотрудника'));
  }

  public getSubdivisionsForDate$(forDate: IForDate, subdivisionIds: number[]): Observable<ISubdivision[]> {
    return this.api1SubdivisionControllerService.getForDate$(forDate.forDate, forDate.startDate, forDate.endDate, subdivisionIds).pipe(exLoadingMessage(this.loadingIndicatorService, 'Загрузка подразделений'));
  }
  public getFinancingSources$(financingSourcesIds: number[]): Observable<IFinancingSource[]> {
    return this.api1FinancingSourceControllerService.getAll$(financingSourcesIds);
  }

  public addVersion$(actionId: SupportedActionEnum, versioningEntity: VersioningItem) {
    return this.api1StaffUnitControllerService.addStaffUnitVersion$(actionId, versioningEntity);
  }

  public deleteVersion$(versionId) {
    return this.api1StaffUnitControllerService.deleteStaffUnitVersion$(versionId);
  }

  public getVersionsByOwnerIdAndActionId$(ownerId, actionId) {
    return this.api1StaffUnitControllerService.getVersionsByOwnerIdAndActionId$(ownerId, actionId);
  }

  public editVersion$(actionId, entity) {
    return this.api1StaffUnitControllerService.editStaffUnitVersion$(actionId, entity);
  }

  public saveNoVersioning$(entity) {
    return this.loadingIndicatorService.addToObservable("Сохранение данных",this.api1StaffUnitControllerService.save$(entity));
  }

  public getPositionsForDate$(forDate: IForDate, positionIds: number[]): Observable<IPosition[]> {
    return this.api1PositionControllerService.getForDate$(forDate.forDate, forDate.startDate, forDate.endDate, positionIds);
  }

  public getFreeRates$(params: IFreeRatesDataSourceService_Params): Observable<FreeRatesDTO>{
    return this.loadingIndicatorService.addToObservable(
      "Загрузка вакантных ставок",
      this.api1StaffUnitsControlControllerService.getFreeRates$(params.positionId, params.executionDutiesFlag, params.startDate, params.endDate, params.excludeStaffUnitOwnerIds));
  }

  public editEndDate$(entity: StaffUnitGridItem, isSave: boolean): Observable<boolean> {
    return this.loadingIndicatorService.addToObservable("Сохранение даты окончания",this.api1StaffUnitControllerService.editEndDate$(entity.endDate, [{id: entity.id, timestamp: entity.timestamp}], isSave));
  }

  public editStartDate$(entity: StaffUnitGridItem, isSave: boolean): Observable<boolean> {
    return this.loadingIndicatorService.addToObservable("Сохранение даты начала",this.api1StaffUnitControllerService.editStartDate$(entity.startDate, [{id: entity.id, timestamp: entity.timestamp}], isSave));
  }

  public getPositionsWithExtendedDataForDate$(params: IPositionWithExtendedDataDataSourceService_Params): Observable<PositionWithExtendedData[]> {
    return this.api1StaffUnitsControlControllerService.getPositionsForDate$(params.subdivisionIds, params.forDate, params.startDate, params.endDate, params.positionIds).pipe(exLoadingMessage(this.loadingIndicatorService, 'Загрузка позиций'));
  }

  protected useSignalR$(): Observable<Observable<any>> | null {
    return this.dbChangedListener.onMulti({
      positions: Position,
      employees: Employee,
      staffUnits: StaffUnit,
    })
    .pipe(
      map(value => value.data),
      map(value => {

        let retval =  {

          employees: new DataHasOwnerStateBuilder(value.employees, this.dataSource.data2, x => x.employeeId).build_()
            .source
            .filter(x => x.state !== 'added') //добавленные сотрудника не интересуют
            .filter(x=> x.dataItem) //Не интересуют сотрудники, котрые не относятся к нашим данным
            .map(x => x.dataItem.id),

          positions: new DataHasOwnerStateBuilder(value.positions, this.dataSource.data2, x => x.positionId).build_()
          .source
          .filter(x => x.state !== 'added') //добавленные позиции не интересуют
          .filter(x=> x.dataItem) //Не интересуют позиции, котрые не относятся к нашим данным
          .map(x => x.dataItem.id),

          staffUnits: new DataHasOwnerStateBuilder(value.staffUnits, this.dataSource.data2,  x => x.id).build_()
            .source.filter(x=>
              (x.state === 'added' && this.paramsDataSource.data.positionId === x.signalR.currentOrOrigin.positionId)
              || x.state === 'modified' || x.state === "deleted"
              )
            .map(x => x.dataItem?.id ?? x.signalR.currentOrOrigin.ownerId)
        }
        return retval;
      }),
      map(value => ([
        ...value.positions,
        ...value.employees,
        ...value.staffUnits
      ])),
      map(value => this.reloadFromSignalR$(value))
    )
  }

  /** Получить {@link IStaffUnitType} */
  public getStaffUnitTypes$() {
    return this.loadingIndicatorService.addToObservable(
      "Загрузка видов занятости",
      this.api1StaffUnitTypeControllerService.getByIds$(this._alloweStaffUnitTypes)
    );
  }

  public addStaffUnit$(entity: IStaffUnitOptional) {
    return this.loadingIndicatorService.addToObservable(
      "Добавление исполнения должностей",
      this.api1StaffUnitControllerService.addStaffUnit$(entity));
  }

  public getEmployeesForDate$(forDate: IForDate, employeeIds: number[]): Observable<IEmployee[]>{
    return this.api1EmployeeControllerService.getForDate$(forDate.forDate, forDate.startDate, forDate.endDate, employeeIds);
  }

  public deleteStaffUnit$(entity: Pick<IStaffUnit, 'id' | 'timestamp'>) {
    return this.loadingIndicatorService.addToObservable(
      "Удаление исполнения должности",
      this.api1StaffUnitControllerService.delete$(entity.id, entity.timestamp));
  }

  public settingsRateStep$(): Observable<number> {
    return this.settingsService.rateStepWithCash$
  }

  protected _reloadData$(params: StaffUnitsGridComponentDataSourceService_Params): Observable<StaffUnitGridItem[]> {
    return this._reloadFromRemoteByIds$(params, null);
  }

  protected _reloadFromRemoteByIds$(params: StaffUnitsGridComponentDataSourceService_Params, targets: StaffUnitGridItem["id"][]): Observable<StaffUnitGridItem[]> {
    return this.loadingIndicatorService.addToObservable(
      "Загрузка штатных единиц",
      this.api1StaffUnitsControlControllerService.getForDate$(
        params.positionId,
        params.forDate,
        params.startDate,
        params.endDate,
        targets));
  }
}

export interface StaffUnitsGridComponentDataSourceService_Params extends IForDate {
  positionId: number;
}
