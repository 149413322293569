import { IEntityId } from '../interfaces/IEntityId';
import { IEntityModify } from '../interfaces/IEntityModify';
import { IEntityComment } from '../interfaces/IEntityComment';
import { IEntityDeletedFlag } from '../interfaces/IEntityDeletedFlag';
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';

export interface IFinancingSource extends IEntityId, IEntityModify, IEntityComment, IEntityDeletedFlag {
  /** Наименование источника финансирования */
  name: string;
  /** Короткое название */
  shortName: string;
}

@classBackend('FinancingSource', 'stafflist')
@className('FinancingSource')
/** Источник финансирования */
export class FinancingSource implements IFinancingSource {
  constructor(public id: number,
              public modifiedUserId: number | null,
              public modifiedDate: Date,
              public comment: string,
              public deletedFlag: boolean,
              public name: string,
              public shortName: string,
  ) {
  }
}
