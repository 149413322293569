import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { ReportDatesComponentSharedService } from 'src/app/components/report-dates/services/report-dates-component-shared.service';
import { KendoTreeListDataSourceSelection } from '../../../../../../../src/app/classes/array-data-sources/selections/kendo-treelist-array-data-source-selection';
import { map } from 'rxjs';
import { ReportDateValues } from '../../../../../../../src/app/components/report-dates/classes/report-date-values';
import { trace } from '../../../../../../../src/app/modules/trace/operators/trace';
import { exErrorHandler } from '../../../../../../../src/app/operators/ex-error-handler';
import { DisplayErrorsService } from '../../../../../../../src/app/components/display-errors/services/display-errors.service';
import { TracerServiceBase } from '../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { SubdivisionsDirectoryTreeListComponentDataSourceService } from '../../subdivisions/directory-treelist/services/subdivisions-directory-tree-list-component-data-source.service';
import { SubdivisionTreeListItem } from '../../../../../../../src/app/services/webApi/webApi1/controllers/api1-subdivisions-directory-treelist-control-controller.service';

@Component({
  selector: 'app-directories-subdivisions-directory',
  templateUrl: './subdivisions-directory.component.html',
  styleUrls: ['./subdivisions-directory.component.css'],
  providers: [SubdivisionsDirectoryTreeListComponentDataSourceService]
})
export class SubdivisionsDirectoryComponent implements OnInit, OnDestroy {

  private readonly streams$ = { unsubscribe: new ReplaySubject<any>(1) };

  constructor(private readonly reportDatesComponentSharedService: ReportDatesComponentSharedService,
              public readonly dataSourceService: SubdivisionsDirectoryTreeListComponentDataSourceService,
              private readonly displayErrorService: DisplayErrorsService,
              private readonly tracerService: TracerServiceBase) {
  }

  ngOnInit() {
    // Подписка на обновление значений
    this.dataSourceService.reloadData$(
      this.reportDatesComponentSharedService.reportDateValues$
        .pipe(
          map(value => ReportDateValues.toForDate(value)),
          trace(this.tracerService),
          exErrorHandler(this.displayErrorService),
        ),
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}
