import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  FreeRatesDTO,
  StaffUnitGridItem
} from 'src/app/services/webApi/webApi1/controllers/api1-staff-units-control.service';
import {CompositeFilterDescriptor, State} from '@progress/kendo-data-query';
import {IconsUrlHelper} from 'src/app/helpers/icons-url.helper';
import {CellClickExpandedEvent, CellMouseEvent} from 'src/app/services/directives/grid-treelist-expanded-directive.service';
import {TooltipDirective} from '@progress/kendo-angular-tooltip';
import {
  TracerServiceBase
} from "../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {traceClass} from "../../../../../../../src/app/modules/trace/decorators/class.decorator";
import {traceFunc} from "../../../../../../../src/app/modules/trace/decorators/func.decorator";
import {
  versioningAndNotVersioningDirectoryServiceProvider
} from 'src/app/components/directory-edit/services/providers/versioning-and-not-versioning-directory-service-provider';
import {StaffUnitsGridComponentService2} from './services/staff-units-grid-component.service';
import {CellClickEvent, DataBindingDirective} from '@progress/kendo-angular-grid';
import {xnameofPath} from 'src/app/functions/nameof';
import {StaffUnitsGridComponentDataSourceServiceBase} from './services/staff-unts-grid-component-data.service';
import {
  KendoGridArrayDataSourceSelection
} from 'src/app/classes/array-data-sources/selections/kendo-grid-array-data-source-selection';
import {combineLatest, defer, map, NEVER, Observable, of, ReplaySubject, switchMap, take, takeUntil} from 'rxjs';
import {exCreateService$} from 'src/app/operators/ex-create-service.operator';
import {DbChangedListener} from 'src/app/services/signal-r/listeners/db-changed-listener';
import {EmployeeDataSourceService} from 'src/app/services/common-data-source-services/employee-data-source.service';
import {
  StaffUnitTypeDataSourceService
} from 'src/app/services/common-data-source-services/staff-unit-type-data-source.service';
import {FreeRatesDataSourceService} from 'src/app/services/common-data-source-services/free-rates-data-source.service';
import {PositionDataSourceService} from 'src/app/services/common-data-source-services/position-data-source.service';
import {NotVersioningField} from 'src/app/components/directory-edit/services/classes/fields/not-versioning-field';
import {FieldType} from 'src/app/components/directory-edit/services/classes/fields/field-type';
import {
  TextAreaFieldControl
} from 'src/app/components/directory-edit/services/classes/field-controls/text-area-field-control';
import {DateFieldControl} from 'src/app/components/directory-edit/services/classes/field-controls/date-field-control';
import {VersioningField} from 'src/app/components/directory-edit/services/classes/fields/versioning-field';
import {
  NumberFieldControl
} from 'src/app/components/directory-edit/services/classes/field-controls/number-field-control';
import {
  DropDownListFieldControl
} from 'src/app/components/directory-edit/services/classes/field-controls/dropdownlist-field-control';
import {DirectoryEditService} from 'src/app/components/directory-edit/services/directory-edit.service';
import {DirectoryEditRef} from 'src/app/components/directory-edit/services/ref-services/directory-edit-ref';
import {trace} from 'src/app/modules/trace/operators/trace';
import {
  FinancingSourceDataSourceService
} from 'src/app/services/common-data-source-services/financing-source-data-source.service';
import {VersioningItem} from 'src/app/classes/directories/edit/versioningItem';
import {StaffUnitTypeEnum} from "../../../../../../../src/app/classes/domain/enums/StaffUnitTypeEnum";
import {
  HierarchiStringsConflictsDialogService
} from 'src/app/components/hierarchi-strings-conflicts/services/hierarchi-strings-conflicts-dialog.service';
import {
  SubdivisionDataSourceService
} from 'src/app/services/common-data-source-services/subdivision-data-source.service';
import {
  PositionWithExtendedDataDataSourceService
} from 'src/app/services/common-data-source-services/position-with-extended-data-data-source.service';
import {exMinMaxStaffUnitFreeRate} from '../ex-min-max-staff-unit-free-rate.operator';
import {StorageLocationEnum, StorageOptions} from 'src/app/services/storages/custom-storage.service';
import {exElementByIndexOr} from 'src/app/operators/ex-element-by-index-or';
import { SupportedActionEnum } from 'src/app/classes/domain/enums/supported-action-enum';
import { CheckboxFieldControl } from 'src/app/components/directory-edit/services/classes/field-controls/checkbox-field-control';
import {ContextItemBase} from "../../../../../../../src/app/classes/context-menu-items/context-item-base";
import {ContextMenuComponent} from "@progress/kendo-angular-menu";
import {
  Api1PrintReportControllerService
} from "../../../../../../../src/app/services/webApi/webApi1/controllers/api1-print-report-controller.service";
import * as FileSaver from "file-saver";
import * as moment from "moment/moment";
import {
  DisplayErrorsService
} from "../../../../../../../src/app/components/display-errors/services/display-errors.service";
import {AuthService} from "../../../../../../../src/app/modules/auth/services/auth.service";

@Component({
  selector: 'app-staff-units-grid2',
  templateUrl: './staff-units-grid.component.html',
  styleUrls: ['./staff-units-grid.component.css'],
  providers: [versioningAndNotVersioningDirectoryServiceProvider, StaffUnitsGridComponentService2, HierarchiStringsConflictsDialogService]
})
@traceClass('StaffUnitsGridComponent')
export class StaffUnitsGridComponent implements OnInit, OnDestroy {

  private _dataSource: StaffUnitsGridComponentDataSourceServiceBase;
  @Input() public get dataSource():StaffUnitsGridComponentDataSourceServiceBase {
    return this._dataSource;
  }
  public set dataSource(value: StaffUnitsGridComponentDataSourceServiceBase){
    if(this._dataSource) {
      throw Error("Повторная установка dataSource не поддерживается!");
     }
    this._dataSource = value;
  }

  private _selection: KendoGridArrayDataSourceSelection<StaffUnitGridItem, number>;
  @Input() public get selection():KendoGridArrayDataSourceSelection<StaffUnitGridItem, number> {
    return this._selection;
  }
  public set selection(val: KendoGridArrayDataSourceSelection<StaffUnitGridItem, number>){
    if(this._selection) {
      throw Error("Повторная установка selection не поддерживается!");
     }
    this._selection = val;
    this.transferButtonEnable$ = val.selectedItems.data$.pipe(
      map(value => {
        if(value.length !== 1){
          return false;
        }

        return this.supportedTransferTypes.findIndex(x => x === value[0].typeId) > -1;
      }),
      takeUntil(this.streams$.unsubscribes)
    )
  }

  /** Поддерживаемые типы {@link StaffUnitTypeEnum} для перевода */
  private readonly supportedTransferTypes = [StaffUnitTypeEnum.Basic, StaffUnitTypeEnum.MoonlighterExternal, StaffUnitTypeEnum.CombinationExternal];

  /** Стрим управление состоянием кнопкой ПЕРЕВОД */
  public transferButtonEnable$: Observable<boolean> = of(false);

  /** Разрешенные типы стаффюнитов для которых доступно добавление и редактирование молока */
  public readonly allowedMilkStaffUnitTypes = [StaffUnitTypeEnum.Basic, StaffUnitTypeEnum.MoonlighterExternal, StaffUnitTypeEnum.MoonlighterInner, StaffUnitTypeEnum.Duty];

  /** Директива для доступа и работе с данными грида*/
  @ViewChild(DataBindingDirective) public dataBindingDirective: DataBindingDirective;

  /** Директива всплывающих подсказок */
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;

  /** Имена колонок */
  public fieldsNames = xnameofPath(StaffUnitGridItem,'');

  /** Данные для всплывающих подсказок */
  public dataForTooltip: StaffUnitGridItem;

  /** Состояние грида при инициализации */
  public gridState: State = {
    sort: [{
      field: this.fieldsNames.fio.toString(),
      dir: 'asc'
    }]
  };

  public sortStorageOptions = new StorageOptions(
    StorageLocationEnum.LocalStorage,
    `StaffUnitsGridComponent_sortOptions`,
    null, false, false);

  /** Значение строки поиска */
  public searchValue: string = '';

  public employeeDataSourceService$: Observable<EmployeeDataSourceService>;
  public subdivisionDataSourceService$: Observable<SubdivisionDataSourceService>;
  public staffUnitTypesDataSourceService$: Observable<StaffUnitTypeDataSourceService>;
  public freeRatesDataSourceService$: Observable<FreeRatesDataSourceService>;
  public positionDataSourceService$: Observable<PositionDataSourceService>;
  public positionWithExtandedDataDataSourceService$: Observable<PositionWithExtendedDataDataSourceService>;
  public financingSourceDataSourceService$: Observable<FinancingSourceDataSourceService>;

  //Можно ли уволить выбранный staffUnit
  public isCanDismiss$: Observable<boolean>;

  public rateStep: number = 0.25;

  /** Элементы контекстного меню, вызываемого на ячейках таблицы работников */
  public contextMenuItems: ContextItemBase[]

  private readonly fields: FieldType[] = [
    new NotVersioningField(this.fieldsNames.comment.toString(), {isAllowNull: true, title: "Комментарий"}, new TextAreaFieldControl({maxLength: 100})),
    new NotVersioningField(this.fieldsNames.startDate.toString(), {isAllowNull: true, title: "Дата начала"}, new DateFieldControl({maxValue: () => this.directoryEditServiceRef.currentDataItem.endDate, minValue: () => this.directoryEditServiceRef.currentDataItem.position.startDate})),
    new NotVersioningField(this.fieldsNames.endDate.toString(), {isAllowNull: true, title: "Дата окончания"}, new DateFieldControl({minValue: () => this.directoryEditServiceRef.currentDataItem.startDate, maxValue: () => this.directoryEditServiceRef.currentDataItem.position.endDate})),

    new VersioningField(this.fieldsNames.milk.toString(), SupportedActionEnum.stafflist_staffUnit_milk, { isAllowNull: false, title: "Участвует в выплате компенсации за молоко" }, new CheckboxFieldControl()),
    new VersioningField(this.fieldsNames.rate.toString(), SupportedActionEnum.stafflist_staffUnit_rate, {isAllowNull: false, title: "Количество ставок"}, new NumberFieldControl(() => ({minValue: 0, maxValue: defer(() => this.getFreeRatesForVersioningField$().pipe(map(s=> s.freeRates.find(f=> f.financingSource.id === this.directoryEditServiceRef.currentDataItem.financingSourceId)?.rate ?? 0))), decimals: 2, format: 'n2', step: this.rateStep, multipleOf: this.rateStep }))),
    new VersioningField(this.fieldsNames.financingSourceId.toString(), SupportedActionEnum.stafflist_staffUnit_financing_source_id, {isAllowNull: false, title: "Источник финансирования", preGetVersionsData$: (vis)=> this.preGetVersionsDataForFinancingSourceId$(vis) }, new DropDownListFieldControl({ textField: 'shortName', data: defer(() =>this.getFreeRatesForVersioningField$().pipe( map(s=> s.freeRates.filter(f=> f.rate >= this.directoryEditServiceRef.currentDataItem.rate).map(f=> f.financingSource))))}))
  ];

  private directoryEditServiceRef: DirectoryEditRef;

  private streams$ = { unsubscribes: new ReplaySubject<any>(1) }

  constructor(
    private readonly service: StaffUnitsGridComponentService2,
    private readonly dbChangedListener: DbChangedListener,
    private readonly directoryEditService: DirectoryEditService,
    private readonly hscDialogService: HierarchiStringsConflictsDialogService,
    private readonly tracerService: TracerServiceBase,
    private readonly printReportControllerService: Api1PrintReportControllerService,
    private readonly displayErrorsService: DisplayErrorsService,
    private readonly authService: AuthService,
  ) {
  }

  @traceFunc()
  public ngOnInit() {
    this.loadRateStep();

    this.employeeDataSourceService$ = exCreateService$(() => new EmployeeDataSourceService(this.dataSource, this.dbChangedListener));
    this.staffUnitTypesDataSourceService$ = exCreateService$(() => new StaffUnitTypeDataSourceService(this.dataSource));
    this.freeRatesDataSourceService$ = exCreateService$(() => new FreeRatesDataSourceService(this.dataSource, this.dbChangedListener));
    this.positionDataSourceService$ = exCreateService$(() => new PositionDataSourceService(this.dataSource, this.dbChangedListener));
    this.positionWithExtandedDataDataSourceService$ = exCreateService$(() => new PositionWithExtendedDataDataSourceService(this.dataSource, this.dbChangedListener));
    this.financingSourceDataSourceService$ = exCreateService$(() => new FinancingSourceDataSourceService(this.dataSource, this.dbChangedListener));
    this.subdivisionDataSourceService$ = exCreateService$(() => new SubdivisionDataSourceService(this.dataSource, this.dbChangedListener));

    this.isCanDismiss$ = this.selection.selectedItems2.data2$.pipe(exElementByIndexOr<StaffUnitGridItem>(0,undefined), map(item => item?.typeId === StaffUnitTypeEnum.Basic || item?.typeId === StaffUnitTypeEnum.CombinationExternal || item?.typeId === StaffUnitTypeEnum.MoonlighterExternal));

    this.directoryEditServiceRef = this.directoryEditService.init(
      {
        addVersion$: (actionId, versioningEntity) => this.dataSource.addVersion$(actionId, versioningEntity),
        deleteVersion$: (versionId) => this.dataSource.deleteVersion$(versionId),
        getVersionsByOwnerIdAndActionId$: (ownerId, actionId) => this.dataSource.getVersionsByOwnerIdAndActionId$(ownerId, actionId),
        editVersion$: (actionId, entity) => this.dataSource.editVersion$(actionId, entity),

        saveNoVersioning$: (entity:any, field: NotVersioningField) => {
          switch(field.name){
            case this.fieldsNames.endDate.toString():
              return this.hscDialogService.show2$((isSafe) => this.dataSource.editEndDate$(entity, isSafe));

            case this.fieldsNames.startDate.toString():
              return this.hscDialogService.show2$((isSafe) => this.dataSource.editStartDate$(entity, isSafe));

            default: return this.dataSource.saveNoVersioning$(entity);
          }
        },
      }, this.fields);
    this.directoryEditServiceRef.closeOnCurrentEditingDataItemChanged$(this.dataSource.dataSource);
  }


 /** Событие происходит при двойном клике на ячейку */
  public onDblCellClick($event: CellClickExpandedEvent<CellClickEvent>) {
    if (!$event.originalEvent.isEdited) {

      const dataItem:StaffUnitGridItem = $event.originalEvent.dataItem;
      let fieldName = $event.originalEvent.column.field;
      if(fieldName === this.fieldsNames.financingSourceShortName.toString()) {
        fieldName = this.fieldsNames.financingSourceId.toString();
      }

      //Запрещаем редактировать молоко для стафф юнитов которым разрешено редактировать молоко
      if(fieldName === this.fieldsNames.milk.toString() && !this.allowedMilkStaffUnitTypes.some(s=> s === dataItem.typeId)) {
        return;
      }

      this.directoryEditServiceRef.edit(fieldName, dataItem, $event.originalEvent.originalEvent.target);
    }
  }

  /** Событие при клике по ячейке, относящейся к строке определенного работника */
  public onCellClick(rowContextMenu: ContextMenuComponent, e: CellClickEvent) {
    if (!this.authService.tokenAsObj.uCN$.includes('gp115')) return;

    const originalEvent = e.originalEvent;
    originalEvent.preventDefault();

    if(e.type !== "contextmenu") return;

    if(this.supportedTransferTypes.some(x => x === e.dataItem.typeId)){

      this.contextMenuItems = []
      this.contextMenuItems.push(new StaffUnitGridContextItem(
        'Сформировать трудовой договор', false,
        () => this.printDocsForStaffUnit(
          "employment_contract",
          "Трудовой договор",
          e.dataItem.id, e.dataItem.startDate)));

      this.contextMenuItems.push(new StaffUnitGridContextItem(
        'Сформировать дополнительное соглашение о переводе к ТД', false,
        () => this.printDocsForStaffUnit(
          "addition_for_employment_contract",
          "Дополнительное соглашение к ТД",
          e.dataItem.id, e.dataItem.startDate)));

      rowContextMenu.show({
        left: originalEvent.pageX,
        top: originalEvent.pageY,
      });
    }
  }

  /** Получить иконку для сотрудника */
  public getStaffUnitTypeImage(typeId: StaffUnitTypeEnum): string {
    return IconsUrlHelper.getstaffUnitImagePath(typeId, false);
  }

  /** Функция фильтрации поиска, для грида */
  @traceFunc()
  public onFilter(): void {
    this.tracerService.add2('Фильтрация', {obj: this.searchValue});

    const filter = <CompositeFilterDescriptor>{
      logic: "or",
      filters: [
        {
          field: this.fieldsNames.fio.toString(),
          operator: 'contains',
          value: this.searchValue
        }
      ],
    };

    this.dataBindingDirective.skip = 0;
    this.dataBindingDirective.filter = filter;
    this.dataBindingDirective.rebind();
  }

  /** Событие происходит при клике на кнопку добавления */
  @traceFunc()
  public onClickAdd() {
    this.service.showAddStaffUnitDialog(this);
  }

  /** Событие происходит при клике на кнопку удаления */
  @traceFunc()
  public onClickDelete() {
    this.service.showRemoveDialog(() => this.dataSource.deleteStaffUnit$(this.selection.selectedItems.data[0]));
  }

  /** Событие происходит при клике на кнопку перевода*/
  @traceFunc()
  public onClickTransfer() {
    this.service.showTransferStaffUnitDialog(this, this.hscDialogService);
  }

  /** Событие происходит при клике на кнопку увольнения*/
  @traceFunc()
  public onClickDismiss() {
    this.service.showDismissStaffUnitDialog(this, this.hscDialogService);
  }


  /** Событие происходит при наведении мылью на ячейку в таблице */
  public onCellMouseOver(e: CellMouseEvent) {
    if (e.columnComponent?.field === "typeOfEmployment") {
      this.dataForTooltip = e.dataItem;
      this.tooltipDir.show(e.htmlElement);
    }
  }

  /** Событие происходит при отведении мыью с ячейки таблицы */
  public onCellMouseOut(e: CellMouseEvent) {
    this.tooltipDir.hide();
    this.dataForTooltip = null;
  }

  /** Загрузка информации о шаге рейта */
  private loadRateStep() {
    this.dataSource.settingsRateStep$()
    .pipe(trace(this.tracerService), take(1), takeUntil(this.streams$.unsubscribes))
    .subscribe(value => {
      this.rateStep = value;
    });
  }

  /** Стрим заполнения значения для отображения для версий поля FK источника финансирования */
  private preGetVersionsDataForFinancingSourceId$(versioningItems$: Observable<VersioningItem[]>): Observable<VersioningItem[]> {
    return versioningItems$.pipe(
      switchMap(vis =>
        this.financingSourceDataSourceService$.pipe(
          switchMap(s=>
              s.reloadData$({
                financingSourcesIds: vis.map(m=> +m.valueAsString)
              })
            ),
            switchMap(s=> s.data$),
            map(
              m=> vis.map(v=> ({...v, displayValue: m.find(f=> f.id === +v.valueAsString)?.shortName})
            )
          )
        )
      ),
      takeUntil(this.directoryEditServiceRef.onClose$)
    );
  }

  /** Возвращает стрим для получения свободных ставок
   * (Биндится на изменение дат начала и/или окочания) и автоматически перезагружает данные
   */
  private getFreeRatesForVersioningField$(): Observable<FreeRatesDTO> {
    return combineLatest({
      startDateEvent: this.directoryEditServiceRef.versioning.onStartDateChangeWithInit$,
      endDateEvent: this.directoryEditServiceRef.versioning.onEndDateChangeWithInit$
    })
    .pipe(
      switchMap(combined =>
        this.freeRatesDataSourceService$
        .pipe(
          switchMap(
            s=> s.reloadData$({
                startDate: combined.startDateEvent?.data?.newValue,
                endDate: combined.endDateEvent?.data?.newValue,
                executionDutiesFlag: this.directoryEditServiceRef.currentDataItem.executionDutiesFlag,
                excludeStaffUnitOwnerIds: [this.directoryEditServiceRef.currentDataItem.id],
                positionId: this.directoryEditServiceRef.currentDataItem.positionId
              })
            ),
            switchMap(s=> s.data$),
            exMinMaxStaffUnitFreeRate(0,1)
          )
        ),
        takeUntil(this.directoryEditServiceRef.onEndEdit$)
    );
  }

  public ngOnDestroy(): void {
    this.streams$.unsubscribes.next(null);
    this.streams$.unsubscribes.complete();
  }

  /** Формирование печатной формы трудового договора */
  private static contractType: "employment_contract" | "addition_for_employment_contract";
  private printDocsForStaffUnit(innerKey: typeof StaffUnitsGridComponent.contractType, name: string, staffUnitId: number, staffUnitStartDate: Date){
    this.printReportControllerService.printEmploymentContract$(innerKey, staffUnitId, staffUnitStartDate)
      .subscribe({
        next: resp => {
          FileSaver.saveAs(resp, `${name}_${moment().format('DD_MM_yyyy')}.xlsx`);
        },
        error: err => {
          this.displayErrorsService.handleError(err);
        },
      });
  }
}

/** Элемент контекстного меню при клике ПКМ по строке таблицы StaffUnits */
export class StaffUnitGridContextItem extends ContextItemBase {
  constructor(text: string, disabled: boolean, select: () => void) {
    super(text, '', disabled, select);
  }
}
