import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {OccupationType} from "../../../classes/domain/POCOs/stafflist/OccupationType";
import {FinancingSource} from "../../../classes/domain/POCOs/stafflist/FinancingSource";
import * as moment from "moment";
import {
  LimitStaffUnitRatesReportSettings
} from "../../../services/webApi/webApi1/controllers/api1-print-report-controller.service";
import {Observable, ReplaySubject} from 'rxjs';
import {
  SubdivisionsTreelistComponentDataSourceServiceBase
} from "../../subdivisions/subdivisions-treelist/services/subdivisions-treelist-component-data.service";
import {CheckableSettings} from "@progress/kendo-angular-treeview";
import {takeUntil} from "rxjs/operators";
import {ArrayDataSourceSelection} from "../../../classes/array-data-sources/selections/array-data-source-selection";
import {ISubdivision} from "../../../classes/domain/POCOs/stafflist/Subdivision";
import {
  LimitStaffUnitsTreeListDataSourceService
} from "../../../../../projects/stafflist/src/app/components/limit-staff-unit/limit-staff-units-tree-list/services/limit-staff-units-tree-list-data-source.service";
import {TracerServiceBase} from "../../../modules/trace/tracers2/trace-services/tracer-base.service";
import {traceClass} from "../../../modules/trace/decorators/class.decorator";
import {trace} from "../../../modules/trace/operators/trace";

@Component({
  selector: 'app-limit-staffunit-rates-report-settings',
  templateUrl: './limit-staffunit-rates-report-settings.component.html',
  styleUrls: ['./limit-staffunit-rates-report-settings.component.css']
})
@traceClass('LimitStaffunitRatesReportSettingsComponent')
export class LimitStaffunitRatesReportSettingsComponent implements OnInit {

  public form: FormGroup;
  public occupationTypes$ : Observable<OccupationType[]>;

  public financingSources$ : Observable<FinancingSource[]>;

  public unsubscribe$ = new ReplaySubject<any>(1);

  private _subdivisionDataSourceService: SubdivisionsTreelistComponentDataSourceServiceBase;
  @Input()
  public get subdivisionDataSourceService() : SubdivisionsTreelistComponentDataSourceServiceBase {
    return this._subdivisionDataSourceService;
  }
  public set subdivisionDataSourceService(value: SubdivisionsTreelistComponentDataSourceServiceBase) {
    this._subdivisionDataSourceService = value;
  }
  @Input() public selection: ArrayDataSourceSelection<ISubdivision, number>;

  // Настройки checkBox TreeView списка подразделений
  public get checkableSettings(): CheckableSettings {
    return {
      checkChildren: false,
      checkParents: false,
      enabled: true,
      mode: 'multiple',
      checkOnClick: true
    };
  }

  @Output() public reportDateChange$: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() public cancel$: EventEmitter<void> = new EventEmitter<void>();
  @Output() public print$: EventEmitter<LimitStaffUnitRatesReportSettings> = new EventEmitter<LimitStaffUnitRatesReportSettings>();

  constructor(private readonly traceService: TracerServiceBase,
              private readonly limitStaffUnitsTreeListDataSourceService : LimitStaffUnitsTreeListDataSourceService) { }

  public ngOnInit(): void {
    this.initForm();
    this.occupationTypes$ = this.limitStaffUnitsTreeListDataSourceService.getOccupationTypes$(null);
    this.financingSources$ = this.limitStaffUnitsTreeListDataSourceService.getFinancingSources$(null);
  }

  private initForm(){
    this.form = new FormGroup({
      printFormInnerKey: new FormControl<string>(''),
      reportDate : new FormControl<Date>(moment(new Date).startOf("month").toDate(), {validators:[Validators.required], updateOn: "blur"}),
      occupationTypeIds: new FormControl<number[]>([], [Validators.required]),
      financingSourceIds: new FormControl<number[]>([], [Validators.required]),
      subdivisionIds: new FormControl<number[]>([])
    });

    this.form.controls.reportDate.valueChanges.pipe(trace(this.traceService), takeUntil(this.unsubscribe$))
      .subscribe(value => this.reportDateChange$.emit(value));

    this.form.controls.reportDate.updateValueAndValidity({emitEvent: true});

    this.form.markAllAsTouched();
}

  public onClickOk() {
    this.print$.next(new LimitStaffUnitRatesReportSettings(
      '', // будет заполнено в обработчике событий диалогового окна
      this.form.controls.reportDate.value,
      this.form.controls.occupationTypeIds.value,
      this.form.controls.financingSourceIds.value,
      this.form.controls.subdivisionIds.value));
    this.print$.complete();
  }

  public onClickCancel() {
    this.cancel$.next();
    this.cancel$.complete();
  }

  public setCheckedSubdivisionsValue($event: Array<number>) {
    this.form.controls.subdivisionIds.setValue($event);
  }

  public setCheckedOccupationTypeIds($event: Array<OccupationType>) {
    this.form.controls.occupationTypeIds.setValue($event.map(occType => occType.id));
  }

  public setCheckedFinancingSourceIds($event: Array<FinancingSource>) {
    this.form.controls.financingSourceIds.setValue($event.map(finSource => finSource.id));
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
