<div kendoDialogContainer></div>

<kendo-grid [kendoGridBinding]="dataSourceService.dataSource.data$ | async"

            [groupable]="false"
            [reorderable]="false"
            [resizable]="false"

            [style.maxHeight.%]="100"

            [sortable]="{
              allowUnsort: true,
              mode: 'single'
            }"
            [sort]="[{
              field: 'lastName',
              dir: 'asc'
            }]"
            [pageable]="true"
            scrollable="scrollable"
            [pageSize]="100"

            KendoGridExpanded
            [kendoGridExpandDetailsBy]="expandDetailsBy"
            [(expandedDetailKeys)]="expandedDetailKeys"

            (dbCellClick)="onDblCellClick($event)"

            kendoGridSelectBy
            [KendoGridSelectByArrayDataSourceSelection]="selection"

            [selectable]="{
              mode: 'single',
              enabled: true
            }"
>
  <ng-template kendoGridToolbarTemplate>
    <div class="grid-header-content">
      <div class="grid-header-content-buttons">
        <button kendoButton icon="plus" (click)="onClickAdd()" primary="true" [size]="'small'">Добавить</button>
        <span [title]="currentSelectedItem?.isWork === true ? 'Удаление оформленного сотрудника не возможно' : ''" style="margin-right: 10px">
        <button kendoButton
                icon="delete"
                (click)="onClickDelete()"
                primary="true"
                [disabled]="!currentSelectedItem || currentSelectedItem?.isWork === true"
                [size]="'small'"
        >Удалить</button>
      </span>
        <button kendoButton icon="import" (click)="onClickImportExtendedData()" [size]="'small'">Импорт данных</button>
      </div>
      <div>
        <kendo-textbox [size]="'small'" class="search-input" placeholder="Поиск" [(value)]="searchValue" (afterValueChanged)="onFilter()" [clearButton]="true"></kendo-textbox>
      </div>
    </div>
  </ng-template>
  <kendo-grid-column [field]="fieldsNames.code.toString()" title="Табельный №" [width]="120"></kendo-grid-column>
  <kendo-grid-column [field]="fieldsNames.lastName.toString()" title="Фамилия" [width]="150"></kendo-grid-column>
  <kendo-grid-column [field]="fieldsNames.firstName.toString()" title="Имя" [width]="150"></kendo-grid-column>
  <kendo-grid-column [field]="fieldsNames.patronymic.toString()" title="Отчество" [width]="160"></kendo-grid-column>

  <kendo-grid-column [field]="fieldsNames.gender.toString()" title="Пол" [width]="160">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.gender | genderText}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [field]="fieldsNames.birthday.toString()" title="Дата рождения" [width]="160">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.birthday}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [field]="fieldsNames.startDate.toString()" title="Дата начала" [width]="135">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.startDate | kendoDate : 'dd.MM.yyyy'}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [field]="fieldsNames.endDate.toString()" title="Дата окончания" [width]="135">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.endDate | kendoDate : 'dd.MM.yyyy'}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [field]="fieldsNames.state.toString()" title="Состояние" [width]="120">
  </kendo-grid-column>
  <kendo-grid-column [field]="fieldsNames.passportsString.toString()" title="Паспортные данные" [width]="300"></kendo-grid-column>
  <kendo-grid-column [field]="fieldsNames.innString.toString()" title="ИНН" [width]="150"></kendo-grid-column>
  <kendo-grid-column [field]="fieldsNames.snilsString.toString()" title="СНИЛС" [width]="150"></kendo-grid-column>
  <kendo-grid-column [field]="fieldsNames.addressString.toString()" title="Место жительства" [width]="350"></kendo-grid-column>
  <kendo-grid-column [field]="fieldsNames.phoneString.toString()" title="Номер телефона" [width]="150"></kendo-grid-column>
  <kendo-grid-column [field]="fieldsNames.comment.toString()" title="Комментарий" [sortable]="false" [width]="220">
  </kendo-grid-column>

  <ng-template kendoGridDetailTemplate let-dataItem>
    <app-edit-employee-names-declensions [employeeId]="dataItem.id" [employeeFullName]="dataItem.fullName"></app-edit-employee-names-declensions>
  </ng-template>
</kendo-grid>

<ng-template #importSettingsTemplate let-service="service">
  <app-employee-import-settings [service]="service"></app-employee-import-settings>
</ng-template>
