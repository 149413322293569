import { Injectable, OnDestroy } from "@angular/core";
import { DialogAction, DialogService } from "@progress/kendo-angular-dialog";
import { combineLatest, map, Observable, ReplaySubject, take, takeUntil } from 'rxjs';
import { StaffUnit } from "src/app/classes/domain/POCOs/stafflist/StaffUnit";
import { ForDate } from "src/app/classes/for-date";
import { DisplayErrorsService } from "src/app/components/display-errors/services/display-errors.service";
import { HierarchiStringsConflictsDialogService } from "src/app/components/hierarchi-strings-conflicts/services/hierarchi-strings-conflicts-dialog.service";
import { DateHelper } from "src/app/helpers/dateHelper";
import { traceClass } from "src/app/modules/trace/decorators/class.decorator";
import { traceFunc } from "src/app/modules/trace/decorators/func.decorator";
import { trace } from "src/app/modules/trace/operators/trace";
import { TracerServiceBase } from "src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import { KendoNotificationService } from "src/app/services/kendo-notification.service";
import { AddStaffUnitFormComponent, AddStaffUnitFormComponent_Data, AddStaffUnitFormComponent_DataSourceService } from "../../add/add-staffunit-form.component";
import { DismissStaffUnitFormComponent } from "../../dismiss/dismiss-staff-unit-form.component";
import { TransferStaffUnitsFormComponent, TransferStaffUnitsFormComponent_Data, TransferStaffUnitsFormComponent_DataSourceService } from "../../transfer/transfer-staff-units.component";
import { StaffUnitsGridComponent } from "../staff-units-grid.component";

@Injectable()
@traceClass('StaffUnitsGridComponentService2')
export class StaffUnitsGridComponentService2 implements OnDestroy {

  private streams$: {
    unsubscribes: ReplaySubject<any>
  } = {
    unsubscribes: new ReplaySubject<any>(1)
  }

  constructor(
    private readonly dialogService: DialogService,
    private readonly kendoNotificationService: KendoNotificationService,
    private readonly displayErrorsService: DisplayErrorsService,
    private readonly tracerService: TracerServiceBase ) {
  }

  /** Открывает диалоговое окно удаления исполнения должности */
  @traceFunc()
  public showRemoveDialog(deleteStaffUnit$: () => Observable<any>) {

    const removeDialogRef = this.dialogService.open({
      title: 'Подтвердите удаление',
      content: 'Вы уверены, что хотите удалить выбранное исполнение должности?\nДальнейшее восстановление будет не возможно!',
      actions: [
        { text: 'Нет' },
        { text: 'Да, удалить', themeColor: 'primary' }
      ],
      width: 450,
      height: 200,
      minWidth: 250
    });

    removeDialogRef.result.subscribe((result: DialogAction) => {
      if (result.themeColor === 'primary') {
        deleteStaffUnit$()
          .pipe(
            trace(this.tracerService, { description: 'Удаление исполнение должности' }),
            take(1),
            takeUntil(this.streams$.unsubscribes)
          ).subscribe({
            error: error => this.displayErrorsService.handleError(error)
          });
      }
      removeDialogRef?.close();
    });
  }


  /** Открывает диалоговое окно добавления исполнения должности */
  @traceFunc()
  public showAddStaffUnitDialog(component: StaffUnitsGridComponent) {

    const addStaffUnitDialogRef = this.dialogService.open({
      title: `Добавление исполнения должностей`,

      content: AddStaffUnitFormComponent,
      width: "90%",
      maxHeight: "90%"
    });

    const addDataSource = new AddStaffUnitFormComponent_DataSourceService();
    combineLatest([
      component.employeeDataSourceService$,
      component.staffUnitTypesDataSourceService$,
      component.freeRatesDataSourceService$,
      component.positionDataSourceService$
    ]).pipe(
      takeUntil(addStaffUnitDialogRef.result),
      takeUntil(this.streams$.unsubscribes)
      )
    .subscribe( ([employees, staffUnitTypes, freeRates, positions]) => {

      positions.reloadData$(component.dataSource.paramsDataSource.data$.pipe(map(m=> {
        return {
          ...ForDate.Copy(m),
          positionIds: [m.positionId]
        }
      }))).subscribe();

      addDataSource.setData(new AddStaffUnitFormComponent_Data(
        component.rateStep,
        positions,
        employees,
        staffUnitTypes,
        freeRates,
        component.allowedMilkStaffUnitTypes
      ));
    });

    const addFormComponent = addStaffUnitDialogRef.content.instance as AddStaffUnitFormComponent;

    addFormComponent.dataItem = <StaffUnit>{ percent: 0 };
    addFormComponent.dataSource = addDataSource;

    addFormComponent.onSaveEventEmitter.pipe(trace(this.tracerService)).subscribe((entity: StaffUnit) =>
      component.dataSource.addStaffUnit$(entity)
        .pipe(trace(this.tracerService, { maxLength: 200 }), take(1), takeUntil(this.streams$.unsubscribes)).subscribe({
          next: data => {
            if (DateHelper.isDateIntervalsIntersect(data.startDate, data.endDate, component.dataSource.paramsDataSource.data.startDate, component.dataSource.paramsDataSource.data.endDate)) {
              this.kendoNotificationService.showSuccess({ content: "Исполнение должностей добавлено" });
            } else {
              this.kendoNotificationService.showSuccess({ content: "Исполнение должностей добавлено, но оно не входит в диапазон выборки дат отчёта, поэтому оно не будет отображено.", hideAfter: 7000 });
            }
            addStaffUnitDialogRef?.close();
          }, error: error => this.displayErrorsService.handleError(error)
        })
    );

    addFormComponent.onCancelEventEmitter.pipe(trace(this.tracerService)).subscribe(() => { addStaffUnitDialogRef?.close(); });
  }

   /** Открывает диалоговое окно перевода исполнения должности */
   @traceFunc()
   public showTransferStaffUnitDialog(component: StaffUnitsGridComponent, hscDialogService: HierarchiStringsConflictsDialogService) {

     const transferStaffUnitDialogRef = this.dialogService.open({
       title: `Перевод исполнения должности`,

       content: TransferStaffUnitsFormComponent,
       width: "90%",
       maxHeight: "90%"
     });

     const transferDataSource = new TransferStaffUnitsFormComponent_DataSourceService();
     combineLatest([
       component.subdivisionDataSourceService$,
       component.freeRatesDataSourceService$,
       component.positionWithExtandedDataDataSourceService$

     ]).pipe(
       takeUntil(transferStaffUnitDialogRef.result),
       takeUntil(this.streams$.unsubscribes)
       )
     .subscribe( ([subdivisions, freeRates, positions]) => {

       transferDataSource.setData(new TransferStaffUnitsFormComponent_Data(
        subdivisions,
        positions,
        freeRates
       ));
     });

     const transferFormComponent = transferStaffUnitDialogRef.content.instance as TransferStaffUnitsFormComponent;
     transferFormComponent.dataSource = transferDataSource;

     transferFormComponent.onTransferEventEmitter.pipe(trace(this.tracerService)).subscribe((entity) =>
     {
        const currentStaffUnit = component.selection.selectedItems2.data[0];

        transferStaffUnitDialogRef?.close();
        hscDialogService.show2$((isSafe) => component.dataSource.transferStaffUnit$(currentStaffUnit.id, currentStaffUnit.timestamp, entity.positionId, entity.financingSourceId, entity.comment, entity.date, isSafe))
         .pipe(trace(this.tracerService, { maxLength: 200 }), take(1), takeUntil(this.streams$.unsubscribes)).subscribe({
           next: () => {
            this.kendoNotificationService.showSuccess({ content: "Исполнение должностей переведено" });

           }, error: error => this.displayErrorsService.handleError(error)
         })
     }
     );

     transferFormComponent.onCancelEventEmitter.pipe(trace(this.tracerService)).subscribe(() => { transferStaffUnitDialogRef?.close(); });
   }

   /** Открывает диалоговое окно увольнения исполнения должности */
   @traceFunc()
   public showDismissStaffUnitDialog(component: StaffUnitsGridComponent, hscDialogService: HierarchiStringsConflictsDialogService) {

     const dismissStaffUnitDialogRef = this.dialogService.open({
       title: `Увольнение сотрудника`,
       content: DismissStaffUnitFormComponent,
     });

     const dismissFormComponent = dismissStaffUnitDialogRef.content.instance as DismissStaffUnitFormComponent;

     dismissFormComponent.admissionDate = component.selection.selectedItems2.data[0].startDate;

     dismissFormComponent.onDismissEventEmitter.pipe(trace(this.tracerService)).subscribe((res) =>
     {
        const selectedStaffUnit = component.selection.selectedItems2.data[0];

        dismissStaffUnitDialogRef?.close();
        hscDialogService.show2$((isSafe) => component.dataSource.dismissStaffUnit$(selectedStaffUnit, res.date, isSafe))
         .pipe(trace(this.tracerService, { maxLength: 200 }), take(1), takeUntil(this.streams$.unsubscribes)).subscribe({
           next: () => {
            this.kendoNotificationService.showSuccess({ content: "Сотрудник уволен" });

           }, error: error => this.displayErrorsService.handleError(error)
         })
     }
     );

     dismissFormComponent.onCancelEventEmitter.pipe(trace(this.tracerService)).subscribe(() => { dismissStaffUnitDialogRef?.close(); });
   }


  public ngOnDestroy(): void {
    this.streams$.unsubscribes.next(null);
    this.streams$.unsubscribes.complete();
  }

}
