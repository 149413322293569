import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {WebApi1Service} from "../web-api1.service";
import {Observable} from "rxjs";
import { VersioningItem } from "src/app/classes/directories/edit/versioningItem";
import { HttpParamsHelper } from "src/app/helpers/httpParamsHelper";
import { IPositionRateOptional, PositionRate } from "src/app/classes/domain/POCOs/stafflist/PositionRate";
import { SupportedActionEnum } from "src/app/classes/domain/enums/supported-action-enum";

@Injectable({
  providedIn: "root"
})
export class Api1PositionRateControllerService{
  constructor(private httpClient: HttpClient,
              private webApi1Service: WebApi1Service) {
  }




  public addPositionRate$(entity: IPositionRateOptional): Observable<PositionRate>
  {
    return this.httpClient
    .post<PositionRate>(this.webApi1Service.controllers.positionRate.actions.add.toString(),
      entity)
  }

  public getVersionsByOwnerIdAndActionId$(ownerId: number, actionId: SupportedActionEnum): Observable<Array<VersioningItem>>
  {
    return this.httpClient
    .get<Array<VersioningItem>>(this.webApi1Service.controllers.positionRate.actions.getVersionsByOwnerIdAndActionId.toString(),
      {
        params: HttpParamsHelper.getParams([['ownerId', ownerId], ['actionId', actionId]])
      })
  }

  public addPositionVersion$(actionId: SupportedActionEnum, versioningEntity: VersioningItem): Observable<VersioningItem>
  {
    return this.httpClient
    .post<VersioningItem>(this.webApi1Service.controllers.positionRate.actions.addVersion.toString(),
      {
        OwnerId:versioningEntity.ownerId,
        ActionId:actionId,
        Data: versioningEntity
      })
  }

  public editPositionVersion$(actionId: SupportedActionEnum, versioningEntity: VersioningItem): Observable<VersioningItem>
  {
    return this.httpClient
    .post<VersioningItem>(this.webApi1Service.controllers.positionRate.actions.editVersion.toString(),
      {
        OwnerId:versioningEntity.ownerId,
        ActionId:actionId,
        Data: versioningEntity
      })
  }


    public deletePositionVersion$(versionId: number) : Observable<boolean>{
      return this.httpClient.delete<boolean>(this.webApi1Service.controllers.positionRate.actions.deleteVersion.toString(),
        {params: HttpParamsHelper.getParams([["versionId",versionId]])}
        )
    }


}
