import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { map, Observable, ReplaySubject, switchMap, takeUntil } from "rxjs";
import { DataSource } from "src/app/classes/array-data-sources/data-source";
import { IStaffUnit } from "src/app/classes/domain/POCOs/stafflist/StaffUnit";
import { ISubdivision } from "src/app/classes/domain/POCOs/stafflist/Subdivision";
import { ForDate, IForDate } from "src/app/classes/for-date";
import { PropertyWrapFormControlType } from "src/app/classes/types/property-wrap-form-control-type";
import { traceClass } from "src/app/modules/trace/decorators/class.decorator";
import { traceFunc } from "src/app/modules/trace/decorators/func.decorator";
import { TracerServiceBase } from "src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import { exDistinctUntilChanged } from "src/app/operators/ex-distinct-until-changed.operator";
import { IFreeRatesDataSourceService_Params } from "src/app/services/common-data-source-services/free-rates-data-source.service";
import { IPositionWithExtendedDataDataSourceService_Params } from "src/app/services/common-data-source-services/position-with-extended-data-data-source.service";
import { ArrayDataSourceIEntityIdServiceWithParamsBase, DataSourceWithParamsBase } from "src/app/services/data-source-services/data-source.service";
import { FreeRateDTO2, FreeRatesDTO, PositionWithExtendedData } from "src/app/services/webApi/webApi1/controllers/api1-staff-units-control.service";
import { CustomFormValidationFunctions } from "src/app/validators/custom-form-validation-functions.class";

/** Тип формы добавления */
export type TransferStaffUnitsComponentForm = PropertyWrapFormControlType<Pick<IStaffUnit, 'positionId' | 'financingSourceId' | 'comment'> & {
  date: Date;
  subdivisionId: number;
}>;

@Component({
  selector: 'app-transfer-staff-units-form',
  templateUrl: './transfer-staff-unit-form.component.html',
  styleUrls: ['./transfer-staff-unit-form.component.css']
})
@traceClass('TransferStaffUnitsFormComponent')
export class TransferStaffUnitsFormComponent implements OnInit {

  @Input("dataSource") public dataSource: TransferStaffUnitsFormComponent_DataSourceService;

  @Output("onTransfer") public onTransferEventEmitter = new EventEmitter<FormGroup<TransferStaffUnitsComponentForm>['value']>();
  @Output("onCancel") public onCancelEventEmitter = new EventEmitter<boolean>();

  public subdivisions$: Observable<Pick<ISubdivision, 'id' | 'longName'>[]>;
  public positions$: Observable<PositionWithExtendedData[]>;
  public freeRates$: Observable<FreeRateDTO2[]>;

  public expandedSubdivisionsIndices$: Observable<number[]>;

  public form: FormGroup<TransferStaffUnitsComponentForm>;

  private streams$: {
    unsubscribes: ReplaySubject<any>
  } = {
    unsubscribes: new ReplaySubject<any>(1)
  }

  constructor(
    private readonly tracerService: TracerServiceBase) {
  }

  @traceFunc()
  ngOnInit() {
    this.subdivisions$ = this.dataSource.data$.pipe(switchMap(s=> s.subdivisions.dataSource.data$));
    this.positions$ = this.dataSource.data$.pipe(switchMap(s=> s.positions.dataSource.data$));
    this.freeRates$ = this.dataSource.data$.pipe(switchMap(s=> s.freeRates.dataSource.data$), map(s=> s.freeRates));
    this.expandedSubdivisionsIndices$ = this.subdivisions$.pipe(map(m=> m.map(s=> s.id)));
    this.form = this.initForm();
  }

  /** Событие происходит при нажатии на кнопку перевода */
  @traceFunc()
  onClickTransfer() {
    const values = this.form.getRawValue();
    this.tracerService.add2('Нажата кнопка Transfer', { obj: values, maxLength: 1000 })
    this.onTransferEventEmitter.emit(values);
  }

  /** Событие происходит при нажатии на кнопку отменить */
  @traceFunc()
  onClickCancel() {
    this.onCancelEventEmitter.emit(false);
  }


  @traceFunc()
  private reloadAllSources(date: Date) {
    const forDate: IForDate = ForDate.Get({endDate: date, startDate: date});

    this.dataSource.data.subdivisions.reloadData(forDate);

    const subdivisionId = this.form.getRawValue().subdivisionId;

    if(subdivisionId) {
      this.reloadPositions(date, subdivisionId);
    }

    const postionid = this.form.getRawValue().positionId;
    if(postionid) {
      this.reloadFreeRates(date, postionid);
    }
  }

  private reloadFreeRates(date: Date, positionId: number) {
    this.dataSource.data.freeRates.reloadData({startDate: date, endDate: date, positionId: positionId, executionDutiesFlag: false});
  }

  private reloadPositions(date: Date, subdivisionId: number) {
    this.dataSource.data.positions.reloadData({startDate: date, endDate: date, forDate: date, subdivisionIds: [subdivisionId], positionIds: null});
  }

  @traceFunc()
  private initForm(): FormGroup<TransferStaffUnitsComponentForm> {
    const financingSourceIdControl = new FormControl<number>({ value: null, disabled: true }, [Validators.required]);
    const subdivisionIdControl = new FormControl<number>({ value: null, disabled: true }, [Validators.required]);
    const positionIdControl = new FormControl<number>({ value: null, disabled: true }, [Validators.required]);
    const dateControl = new FormControl<Date>(null, { validators: [Validators.required], updateOn: 'blur' });

    CustomFormValidationFunctions.validateDataWithControlValue$(
        this.freeRates$,
        financingSourceIdControl,
        (d,c)=> d.financingSource.id === c
      )
      .pipe(takeUntil(this.streams$.unsubscribes))
      .subscribe();

      CustomFormValidationFunctions.validateDataWithControlValue$(
        this.subdivisions$,
        subdivisionIdControl,
        (d,c)=> d.id === c
      )
      .pipe(takeUntil(this.streams$.unsubscribes))
      .subscribe();

      CustomFormValidationFunctions.validateDataWithControlValue$(
        this.positions$,
        positionIdControl,
        (d,c)=> d.id === c
      )
      .pipe(takeUntil(this.streams$.unsubscribes))
      .subscribe();

    const form = new FormGroup({
      date: dateControl,
      financingSourceId: financingSourceIdControl,
      subdivisionId: subdivisionIdControl,
      positionId: positionIdControl,
      comment: new FormControl({ value: "", disabled: true }, { validators: [Validators.maxLength(100)], updateOn: 'blur' }),
    });

    form.markAllAsTouched();

    dateControl.valueChanges.pipe(exDistinctUntilChanged(dateControl.value)).subscribe(v => {
      //При изменении даты нужно перезагрузить данные для выпадающих списков
      if (v) {
        this.reloadAllSources(v);

        //Включаем контролы после выбора даты (если она не null)
        form.controls.comment.enable();
        form.controls.subdivisionId.enable();
      }
    });

    subdivisionIdControl.valueChanges.pipe(exDistinctUntilChanged(subdivisionIdControl.value)).subscribe(v => {
      //При изменении подразделения нужно перезагрузить данные для позиций
      if (v) {
        const date = this.form.getRawValue().date;
        this.reloadPositions(date, v);

        //Включаем контролы после выбора позиции (если оно не null)
        form.controls.positionId.enable();
      } else {
        //Выключаем контролы после выбора позиции (если оно null)
        form.controls.positionId.disable();
      }
      form.controls.positionId.setValue(null);
    });

    positionIdControl.valueChanges.pipe(exDistinctUntilChanged(positionIdControl.value)).subscribe(v => {
      //При изменении позиции нужно перезагрузить данные для свободных ставок
      if (v) {
        const date = this.form.getRawValue().date;
        this.reloadFreeRates(date, v);

        //Включаем контрол ИФ после выбора позиции
        form.controls.financingSourceId.enable();
      } else {
        //Включаем контрол ИФ после выбора позиции т.к. позиция не правильная
        form.controls.financingSourceId.disable();
      }
      form.controls.financingSourceId.setValue(null);
    });

    form.valueChanges.subscribe(value => {
      this.tracerService.add2('Изменена форма', { obj: value, maxLength: 1000 })
    })

    return form;
  }

  /** Выключенные элементы в выпадающем списке источников финансирования (выключены те у которых нет свободных ставок) */
  public financingSourceDisabled(itemArgs: { dataItem: FreeRateDTO2; index: number }) {
    return itemArgs.dataItem.rate <= 0;
  }

  @traceFunc()
  public ngOnDestroy() {
    this.streams$.unsubscribes.next(null);
    this.streams$.unsubscribes.complete();
  }

}

export class TransferStaffUnit {
  constructor(
    public positionId: number,
    public financingSourceId: number,
    public comment: string
  ){}
}

export class TransferStaffUnitsFormComponent_DataSourceService extends DataSource<TransferStaffUnitsFormComponent_Data> {

}

export class TransferStaffUnitsFormComponent_Data {
  constructor(
      public readonly subdivisions: ArrayDataSourceIEntityIdServiceWithParamsBase<IForDate, Pick<ISubdivision, 'id' | 'longName'>>,
      public readonly positions: ArrayDataSourceIEntityIdServiceWithParamsBase<IPositionWithExtendedDataDataSourceService_Params, PositionWithExtendedData>,
      public readonly freeRates: DataSourceWithParamsBase<IFreeRateParamsType, FreeRatesDTO>,
    ) { }
}
type IFreeRateParamsType = Pick<IFreeRatesDataSourceService_Params, 'startDate' | 'endDate' | 'positionId' | 'executionDutiesFlag'>;
