import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {IPrintFormSettingsComponentBase} from "../abstractions/IPrintFormSettingsComponentBase";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {StaffListReportSettings} from "../../../services/webApi/webApi1/controllers/api1-print-report-controller.service";
import {ReplaySubject} from "rxjs/internal/ReplaySubject";
import {takeUntil} from "rxjs/operators";
import {DropDownItem} from "../../../classes/requestResults/iDropDownItem";
import {DateHelper} from "../../../helpers/dateHelper";
import {
  Api1FinancingSourceControllerService
} from "../../../services/webApi/webApi1/controllers/api1-financing-source-controller.service";
import {FinancingSource} from "../../../classes/domain/POCOs/stafflist/FinancingSource";
import {CheckedSubdivisionsValidator} from "../report-settings-form-validators";
import {
  SubdivisionsTreelistComponentDataSourceServiceBase
} from "../../subdivisions/subdivisions-treelist/services/subdivisions-treelist-component-data.service";
import {CheckableSettings} from "@progress/kendo-angular-treeview";
import {ISubdivision} from "../../../classes/domain/POCOs/stafflist/Subdivision";
import {ArrayDataSourceSelection} from "../../../classes/array-data-sources/selections/array-data-source-selection";

@Component({
  selector: 'app-staff-list-report-settings',
  templateUrl: './staff-list-report-settings.component.html',
  styleUrls: ['./staff-list-report-settings.component.css']
})
export class StaffListReportSettingsComponent implements OnInit, OnDestroy, IPrintFormSettingsComponentBase {

  public form: FormGroup;

  private _subdivisionDataSourceService: SubdivisionsTreelistComponentDataSourceServiceBase;
  @Input()
  public get subdivisionDataSourceService() : SubdivisionsTreelistComponentDataSourceServiceBase {
    return this._subdivisionDataSourceService;
  }
  public set subdivisionDataSourceService(value: SubdivisionsTreelistComponentDataSourceServiceBase) {
    this._subdivisionDataSourceService = value;
  }

  @Input() public selection: ArrayDataSourceSelection<ISubdivision, number>;

  private _checkableSettings: CheckableSettings;
  // Настройки checkBox TreeView списка подразделений
  @Input()
  public get checkableSettings(): CheckableSettings {
    return this._checkableSettings;
  }
  public set checkableSettings(value: CheckableSettings){
    this._checkableSettings = value;
  }

  /** Функция формирования отображаемой в treeView строки из объекта ISubdivision */
  private _displayTextFn: (subdivision: ISubdivision) => string;
  @Input() public get displayTextFn(){ return this._displayTextFn; }
  public set displayTextFn(value){ this._displayTextFn = value; }

  /** Развернуть (отобразить) все элементы списка подразделений при отображении формы настроек */
  @Input() public expandAllSubdivisions: boolean;

  /** Изменение даты, по которой формируются данные (необходимо для выполнения перезагрузки списка подразделений, сформированного на конкретную дату)*/
  @Output() dateChange$: EventEmitter<Date> = new EventEmitter<Date>();

  public date: Date;
  public financingSourceList: Array<DropDownItem> = new Array<DropDownItem>();

  @Output() public cancel$: EventEmitter<void> = new EventEmitter<void>();
  @Output() public print$: EventEmitter<StaffListReportSettings> = new EventEmitter<StaffListReportSettings>();

  public unsubscribe$: ReplaySubject<any> = new ReplaySubject<any>();

  constructor(private financingSourceService: Api1FinancingSourceControllerService) { }

  ngOnInit(): void {
    this.formInit();

    this.financingSourceService.getAll$().pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        value.unshift(new FinancingSource(-1, null,
          null, '', false,'',
          'Все источники финансирования'))

        this.financingSourceList = value.map(x => new DropDownItem(x.id, x.shortName))
      });
  }

  onClickOk() {
    this.print$.next(new StaffListReportSettings(
      '', // будет заполнено в обработчике событий диалогового окна
      this.form.controls.date.value,
      this.form.controls.allSubdivisionsFlag.value,
      this.form.controls.checkedSubdivisions.value,
      this.getFinancingSourceId() ));

    this.print$.complete();
  }

  private getFinancingSourceId() {
    return this.form.controls.financingSource.value.id > 0 ? this.form.controls.financingSource.value.id : null;
  }

  onClickCancel() {
    this.cancel$.next();
    this.cancel$.complete();
  }


  ngOnDestroy(): void {
    this.print$.complete();
    this.cancel$.complete();
    this.dateChange$.complete();
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  private formInit() {
    this.form = new FormGroup({
      date: new FormControl(this.date = DateHelper.getStartOfMounth(new Date(), true), {validators: Validators.required, updateOn: 'blur'}),
      allSubdivisionsFlag: new FormControl(false),
      checkedSubdivisions: new FormControl([], CheckedSubdivisionsValidator()),
      financingSource: new FormControl(null, Validators.required),
      }
    );

    this.form.controls.date.valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.date = value;
        this.dateChange$.emit(value);
      });

    this.form.controls.date.markAsTouched();
    this.dateChange$.emit(this.date);
  }

  /** Установить значение параметра выбранных подразделений на следующем круге eventLoop.
   * Необходимо для устранения ошибки afterChenge */
  public setCheckedSubdivisionsValue(el: number[]){
    setTimeout(() => this.form.controls.checkedSubdivisions.setValue(el));
  }

  /** Установить значение параметра "Выбрать все подразделения" на следующем круге eventLoop.
   * Необходимо для устранения ошибки afterChenge */
  public setAllSubdivisionsFlagValue(el: boolean){
    setTimeout(() => this.form.controls.allSubdivisionsFlag.setValue(el));
  }
}
