import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {StaffUnitType} from "../../../classes/domain/POCOs/stafflist/StaffUnitType";
import {Observable} from "rxjs";
import {
  PrintQuarterSettingsWithSubdivisionsComponent
} from "../print-quarter-settings-with-subdivisions/print-quarter-settings-with-subdivisions.component";
import {FormControl, Validators} from "@angular/forms";
import {
  SubdivisionsTreelistComponentDataSourceServiceBase
} from "../../subdivisions/subdivisions-treelist/services/subdivisions-treelist-component-data.service";
import {ArrayDataSourceSelection} from "../../../classes/array-data-sources/selections/array-data-source-selection";
import {ISubdivision} from "../../../classes/domain/POCOs/stafflist/Subdivision";
import {CheckableSettings} from "@progress/kendo-angular-treeview";
import {
  PrintQuarterSettingsWithSubdivisions, PrintQuarterSettingsWithSubdivisionsAndStaffUnitTypes
} from "../../../services/webApi/webApi1/controllers/api1-print-report-controller.service";
import {
  Api1StaffUnitTypeControllerService
} from "../../../services/webApi/webApi1/controllers/api1-staff-unit-type-controller.service";
import {IPrintFormSettingsComponentBase} from "../abstractions/IPrintFormSettingsComponentBase";

@Component({
  selector: 'app-print-quarter-settings-with-subdivisions-and-staffunittypes',
  templateUrl: './print-quarter-settings-with-subdivisions-and-staff-unit-types.component.html',
  styleUrls: ['./print-quarter-settings-with-subdivisions-and-staff-unit-types.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintQuarterSettingsWithSubdivisionsAndStaffUnitTypesComponent implements IPrintFormSettingsComponentBase{

  private _subdivisionDataSourceService: SubdivisionsTreelistComponentDataSourceServiceBase;
  @Input() public get subdivisionDataSourceService() : SubdivisionsTreelistComponentDataSourceServiceBase {
    return this._subdivisionDataSourceService;
  }
  public set subdivisionDataSourceService(value: SubdivisionsTreelistComponentDataSourceServiceBase){
    this._subdivisionDataSourceService = value;
  }

  @Input() public selection: ArrayDataSourceSelection<ISubdivision, number>;

  //Настройки checkBox TreeView списка подразделений
  private _checkableSettings: CheckableSettings;
  @Input() public get checkableSettings(): CheckableSettings {
    return this._checkableSettings;
  }
  public set checkableSettings(value: CheckableSettings) {
    this._checkableSettings = value;
  }

  private _displayTextFn: (subdivision: ISubdivision) => string;
  /** Функция формирования отображаемой в treeView строки из объекта ISubdivision */
  @Input() public get displayTextFn(){ return this._displayTextFn; }
  public set displayTextFn(value){ this._displayTextFn = value; }

  /** Развернуть (отобразить) все элементы списка подразделений при отображении формы настроек */
  @Input() public expandAllSubdivisions: boolean;

  @Output() public print$: EventEmitter<PrintQuarterSettingsWithSubdivisionsAndStaffUnitTypes> = new EventEmitter<PrintQuarterSettingsWithSubdivisionsAndStaffUnitTypes>();

  @Output() public cancel$: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild("childQuarterSettingsComponent")
  public childSettingsComponent: PrintQuarterSettingsWithSubdivisionsComponent;

  public staffUnitTypes$: Observable<StaffUnitType[]>;
  public staffUnitTypes = new FormControl<StaffUnitType[]>([], Validators.required);
  constructor(private readonly _staffUnitTypeControllerService: Api1StaffUnitTypeControllerService,
              private readonly chngDetector: ChangeDetectorRef,
              ) {
  }

  public ngOnInit() {
    setTimeout(() => {
      this.childSettingsComponent.form.addControl("staffUnitTypes", this.staffUnitTypes);
      this.childSettingsComponent.form.controls.staffUnitTypes.markAsTouched();
      this.staffUnitTypes$ = this._staffUnitTypeControllerService.getAll$();
      this.chngDetector.detectChanges();
    });
  }

  public onPrint($event: PrintQuarterSettingsWithSubdivisions) {
    this.print$.next(new PrintQuarterSettingsWithSubdivisionsAndStaffUnitTypes(
      '', // будет заполнено в обработчике событий диалогового окна
      $event.subPeriodName?.toLowerCase(),
      $event.startDate,
      $event.endDate,
      $event.allSubdivisionsFlag,
      $event.subdivisionOwnerIds,
      this.childSettingsComponent.form.controls.staffUnitTypes.value.map((x: StaffUnitType) => x.id)));
    this.print$.complete();
  }

  public onClickCancel() {
    this.cancel$.next();
    this.cancel$.complete();
  }

  public ngOnDestroy() {
    this.childSettingsComponent.ngOnDestroy();
  }
}
