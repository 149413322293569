import {Injectable} from "@angular/core";
import {HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {concat, Observable, of, switchMap, throwError} from "rxjs";;
import {ResponseObjError} from "../../../classes/requestResults/responseObjError";
import {ArrayExpanded} from "../../../helpers/arrayHelper";
import {
  HierarchiStringsConflictsDialogService
} from "../../../components/hierarchi-strings-conflicts/services/hierarchi-strings-conflicts-dialog.service";
import {HierarchiString} from "../../../classes/requestResults/EditEntityWithDependenciesErrorData";
import {DialogService} from "@progress/kendo-angular-dialog";
import {LoadingIndicatorService} from "../../../services/loading-indicator.service";
import {catchError} from "rxjs/operators";
import {ResponseObj} from "../../../classes/requestResults/response-obj";

/**
 * Сервис, необходим для работы интерцептора {@link CatchStaffUnitApprovedGraphTableInterceptor}
 */
export abstract class CatchStaffUnitApprovedGraphTableInterceptor_ServiceBase {
  /** Метод возвращает табеля в статус 'В работе' */
  abstract tableToWork$(ids: number[]): Observable<void>;
  /** Метод возвращает графики в статус 'В работе' */
  abstract graphToWork$(ids: number[]): Observable<void>;
}

/** Гуид валидационной ошибки, связанной с согласованными табелями/графиками */
const GUID_CODE = '3c43c31e-a724-4d73-9a42-b6d382948ccc';

const GRAPH_NAME = 'Graph';
const TABLE_NAME = 'Table';

/**
 * Интерцептор перехватывает и обрабатывает валидационные ошибки, связанные с редактированием StaffUnit и согласованными графиками/табелями
 */
@Injectable({
  providedIn: "root"
})
export class CatchStaffUnitApprovedGraphTableInterceptor implements HttpInterceptor {

  constructor(private readonly service: CatchStaffUnitApprovedGraphTableInterceptor_ServiceBase,
              private readonly dialogService: DialogService,
              private readonly loadingIndicatorService: LoadingIndicatorService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req.clone())
      .pipe(
        catchError((err, caught) => {

          if(!ResponseObjError.checkType(err) || !ResponseObjError.isBusinessValidationError(err)){
            return throwError(() => err);
          }

          const items = ResponseObjError.getAsBusinessValidationError(err)
            .data
            .filter(x => x.Guid === GUID_CODE);

          if(!items.length){ //Если нет нужных ошибок выходим
            return throwError(() => err);
          }

          const messageMap = new ArrayExpanded(items)
            .flatMap(x => x.Reasons)
            .flatMap(x => x.Results, (outItem, innerItem) => ({
              reasonName: outItem.Name,
              hierarchiStrings: new HierarchiString(innerItem.Message, [])
            }))
            .groupBy(x => x.reasonName, (key, items) => ({
              key,
              hierarchiStrings: items.map(x => x.hierarchiStrings)
            }))
            .toMap(x => x.key, x => x.hierarchiStrings);

          const hierarchiStrings = [
            new HierarchiString('Табеля', messageMap.get(TABLE_NAME) ?? []),
            new HierarchiString('Графики', messageMap.get(GRAPH_NAME) ?? [])
          ].filter(x => x.ChildStrings.length > 0);

          this.loadingIndicatorService.disable();
          return new HierarchiStringsConflictsDialogService(this.dialogService)
            .show([new HierarchiString('Имеются согласованные или находящиеся на согласовании графики/табели. При подтверждении, они будут возвращены в работу.', hierarchiStrings)])
            .onResult$
            .pipe(
              switchMap(value1 => {
                this.loadingIndicatorService.enable();

                if (!value1) {
                  return throwError(() => err);
                }

                const idsMap = new ArrayExpanded(items)
                  .flatMap(x => x.Reasons)
                  .groupBy(x => x.Name, (key, items) => {
                    return {
                      key,
                      ids: new ArrayExpanded(items).map(x => x.Id).distinct().array
                    }
                  })
                  .toMap(x => x.key, x => x.ids);

                const tableToWork$ = idsMap.has(TABLE_NAME)
                  ? this.loadingIndicatorService.addToObservable(
                    'Возврат табелей в работу',
                    this.service.tableToWork$(idsMap.get(TABLE_NAME))
                  )
                  : of(undefined);

                const graphToWork$ = idsMap.has(GRAPH_NAME)
                  ? this.loadingIndicatorService.addToObservable(
                    'Возврат графиков в работу',
                    this.service.graphToWork$(idsMap.get(GRAPH_NAME))
                  )
                  : of(undefined);

                return tableToWork$.pipe(
                  switchMap(x => graphToWork$),
                  switchMap(x => next.handle(req.clone()))
                );
              })
            )

        })
      )
  }

}
