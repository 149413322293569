/** Необходимо для локализации kendo  */
import { IntlModule } from "@progress/kendo-angular-intl";
import '@progress/kendo-angular-intl/locales/ru/all'
import { MessageService } from "@progress/kendo-angular-l10n";
import { KendoMessageService } from "../../../../src/app/services/kendo-message.service";
/** --------------------------------- */

import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { environment } from "../environments/environment";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { WebApiAuthService } from 'src/app/services/webApi/webApiAuth/web-api-auth.service';
import { JwtConfig, JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { EnvironmentService } from 'src/app/services/environment.service';
import { DateHelper } from 'src/app/helpers/dateHelper';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthModule } from 'src/app/modules/auth/auth.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {DialogModule, DialogService, WindowModule} from '@progress/kendo-angular-dialog';
import { InputsModule, NumericTextBoxModule, TextBoxModule } from '@progress/kendo-angular-inputs';
import { DrawerModule, LayoutModule } from '@progress/kendo-angular-layout';
import { FloatingLabelModule, LabelModule } from '@progress/kendo-angular-label';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { SharedComponentModule } from 'src/app/modules/shareds/shared-component/shared-component.module';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { GridModule } from '@progress/kendo-angular-grid';
import { PopupModule } from '@progress/kendo-angular-popup';
import { ContextMenuModule, MenuModule } from '@progress/kendo-angular-menu';
import { DateInputsModule, TimePickerModule } from '@progress/kendo-angular-dateinputs';
import { DropDownListModule, DropDownsModule } from '@progress/kendo-angular-dropdowns';
import {ExcelModule, TreeListModule} from '@progress/kendo-angular-treelist';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { ServerLoggerService } from 'src/app/services/loggers/server-logger.service';
import { ErrorHandlerService } from 'src/app/services/loggers/error-handler.service';
import { DeepServerFormatInterceptor } from 'src/app/modules/auth/interceptors/deep-server-format.interceptor';
import { WorkspaceErrorComponent } from 'projects/timesheet/src/app/components/workspace-error/workspace-error.component';
import { StafflistComponent } from './components/stafflist/stafflist.component';
import { SubdivisionsListComponent } from './components/subdivisions-list/subdivisions-list.component';
import {
  SubdivisionsTreelistComponent
} from 'src/app/components/subdivisions/subdivisions-treelist/subdivisions-treelist.component';
import { ReportDatesComponent } from "../../../../src/app/components/report-dates/report-dates.component";
import { DirectoriesListComponent } from "./components/directories/list/directories-list.component";
import { DirectoriesComponent } from "./components/directories/directories.component";
import { EmployeesDirectoryComponent } from "./components/directories/employees/employees-directory.component";
import { EmployeesGridComponent } from "./components/employers/grid/employees-grid.component";
import { AddEmployeeFormComponent } from "./components/employers/add/add-employee-form.component";
import { SubdivisionsDirectoryComponent } from "./components/directories/subdivisions/subdivisions-directory.component";
import { SubdivisionsDirectoryTreeListComponent } from "./components/subdivisions/directory-treelist/subdivisions-directory-tree-list.component";
import { AddSubdivisionFormComponent } from "./components/subdivisions/add/add-subdivision-form.component";
import { OccupationTypesDirectoryComponent } from "./components/directories/occupation-types/occupation-types-directory.component";
import { OccupationTypesDirectoryGridComponent } from "./components/occupation-types/directory-grid/occupation-types-directory-grid.component";
import { AddOccupationTypeFormComponent } from "./components/occupation-types/add/add-occupation-type-form.component";
import { OccupationsDirectoryComponent } from "./components/directories/occupations/occupations-directory.component";
import { AddOccupationFormComponent } from "./components/occupations/add/add-occupation-form.component";
import { OccupationsDirectoryGridComponent } from "./components/occupations/directory-grid/occupations-directory-grid.component";
import { CodesGridComponent } from "./components/codes/codes-grid.component";
import { EditOccupationsCodesFormComponent } from "./components/occupations/edit-occupations-codes/edit-occupations-codes.component";
import { KendoGridExpandedDirective } from "src/app/directives/kendo-grid-expanded.directive";
import { KendoTreeListExpandedDirective } from "src/app/directives/kendo-treelist-expanded.directive";
import { ReportPeriodsDirectoryComponent } from "./components/directories/report-periods/report-periods.component";
import { AddReportPeriodFormComponent } from "./components/report-periods/add/add-report-period-form.component";
import { ReportPeriodsDirectoryGridComponent } from "./components/report-periods/directory-grid/report-periods-directory-grid.component";
import { WorkModesDirectoryComponent } from "./components/directories/work-modes/work-modes-directory.component";
import { AddWorkModeFormComponent } from "./components/work-modes/add/add-work-mode-form.component";
import { WorkModesDirectoryGridComponent } from "./components/work-modes/directory-grid/work-modes-directory-grid.component";
import { AddPositionRateFormComponent } from "./components/position-rates/add/add-position-rate-form.component";
import { AddPositionRatesGridControlComponent } from "./components/positions/add/add-position-rates-grid-control/add-position-rates-grid-control.component";
import { ChildInputPlaceholderDirective } from "src/app/directives/child-input-placeholder.directive";
import { KendoNumericExpandedDirective } from "src/app/directives/kendo-numeric-expanded.directive";
import { InternetConnectionComponent } from "../../../../src/app/components/internet-connection/internet-connection.component";
import { AppSettingsService } from "../../../../src/app/services/app-settings.service";
import { JwtHelper } from "../../../../src/app/helpers/JwtHelper";
import { tap } from "rxjs/operators";
import { WorkspaceComponent } from "../../../../src/app/components/workspace/workspace.component";
import { WorkspaceServiceBase } from "../../../../src/app/components/workspace/workspace.service";
import { StafflistWorkspaceService } from "../services/stafflist-workspace.service";
import { PrintFormsComponent } from './components/print-forms/print-forms/print-forms.component';
import { PrintFormForDateSettingsComponent } from "../../../../src/app/components/print-form-settings/print-form-for-date-settings/print-form-for-date-settings.component";
import { PrintFormFromtillDatesSettingsComponent } from "../../../../src/app/components/print-form-settings/print-form-fromtill-dates-settings/print-form-fromtill-dates-settings.component";
import { PrintQuarterSettingsWithSubdivisionsComponent } from "../../../../src/app/components/print-form-settings/print-quarter-settings-with-subdivisions/print-quarter-settings-with-subdivisions.component";
import { TreeViewModule } from "@progress/kendo-angular-treeview";
import {
  StaffListReportSettingsComponent
} from "../../../../src/app/components/print-form-settings/staff-list-report-settings/staff-list-report-settings.component";
import {
  PrintFormForDateSettingsWithSubdivisionTreeComponent
} from "../../../../src/app/components/print-form-settings/print-form-for-date-settings-with-subdivision-tree/print-form-for-date-settings-with-subdivision-tree.component";
import { TracerService } from "../../../../src/app/modules/trace/tracers2/trace-services/tracerService";
import { TracerServiceBase } from "../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import { TraceStorageService } from "../../../../src/app/modules/trace/tracers2/trace-storage-services/trace-storage.service";
import { ImportFromExcelComponent } from "src/app/components/import-from-excel/import-from-excel.component";
import { UploadsModule } from "@progress/kendo-angular-upload";
import { EditPassportDataComponent } from "./components/controls/extended-data-controls/edit-passport-data/edit-passport-data.component";
import { EditPassportDataDialogComponent } from "./components/controls/extended-data-controls/edit-passport-data/dialog/edit-passport-data-dialog-component/edit-passport-data-dialog.component";
import { EmployeeImportSettingsComponent } from "./components/employers/import-settings/employee-import-settings.component";
import { IdenticalRequestInterceptor } from "../../../../src/app/modules/auth/interceptors/identical-request.interceptor";
import { WorkSpaceErrorComponentService } from "../../../timesheet/src/app/services/workspace/work-space-error.component.service";
import { CatchErrorInterceptor } from "../../../../src/app/modules/auth/interceptors/catch-error.interceptor";
import { InputTrimDirective } from "src/app/directives/input-trim.directive";
import { TraceInterceptor } from "../../../../src/app/modules/auth/interceptors/trace.interceptor";
import { BlobInterceptor } from "../../../../src/app/modules/auth/interceptors/blob.interceptor";
import { lastValueFrom } from "rxjs";
import { DirectoryEditControlComponent } from "src/app/components/directory-edit/edit-control/directory-edit-control.component";
import { TextBoxDirectoryEditComponent } from "src/app/components/directory-edit/controls/textbox/textbox-directory-edit-control.component";
import { DateDirectoryEditComponent } from "src/app/components/directory-edit/controls/date/date-directory-edit-control.component";
import { DropDownListDirectoryEditComponent } from "src/app/components/directory-edit/controls/dropdownlist/dropdownlist-directory-edit-control.component";
import { ComboboxDirectoryEditComponent } from "src/app/components/directory-edit/controls/combobox/combobox-directory-edit-control.component";
import { MaskedTextBoxDirectoryEditComponent } from "src/app/components/directory-edit/controls/masked-textbox/masked-textbox-directory-edit-control.component";
import { NumberDirectoryEditComponent } from "src/app/components/directory-edit/controls/number/number-directory-edit-control.component";
import { TextAreaDirectoryEditComponent } from "src/app/components/directory-edit/controls/textarea/textarea-directory-edit-control.component";
import { DisplayValidationErrorsComponent } from "src/app/components/directory-edit/display-validation-errors/display-validation-errors.component";
import { CustomDirectoryEditComponent } from "src/app/components/directory-edit/controls/custom-control/custrom-directory-edit-control.component";
import { DirectoryEditNotVersioningComponent } from "src/app/components/directory-edit/not-versioning/directory-edit-not-versioning.component";
import { DirectoryEditVersioningComponent } from "src/app/components/directory-edit/versioning/directory-edit-versioning.component";
import { CheckboxDirectoryEditComponent } from "src/app/components/directory-edit/controls/checkbox/checkbox-directory-edit-control.component";
import {CustomStorageService} from "../../../../src/app/services/storages/custom-storage.service";
import {CustomStorageRealService} from "../../../../src/app/services/storages/custom-storage-real.service";
import {CryptService} from "../../../../src/app/services/cryptServices/crypt.service";
import { DropDownTreeDirectoryEditComponent } from "src/app/components/directory-edit/controls/dropdowntree/dropdowntree-directory-edit-control.component";
import { StaffUnitsGridComponent } from "./components/staff-units/staff-units-grid/staff-units-grid.component";
import { AddPositionFormComponent } from "./components/positions/add/add-position-form.component";
import { PositionsGridComponent } from "./components/positions/grid/positions-grid.component";
import { AddStaffUnitFormComponent } from "./components/staff-units/add/add-staffunit-form.component";
import { LimitStaffUnitDirectoryComponent } from './components/limit-staff-unit/limit-staff-unit-directory/limit-staff-unit-directory.component';
import { AddLimitStaffUnitComponent } from './components/limit-staff-unit/add-limit-staff-unit/add-limit-staff-unit.component';
import { LimitStaffUnitsTreeListComponent } from './components/limit-staff-unit/limit-staff-units-tree-list/limit-staff-units-tree-list.component';
import { AddLimitStaffUnitRateGridControlComponent } from './components/limit-staff-unit/add-limit-staff-unit/add-limit-staff-unit-rate-grid-control/add-limit-staff-unit-rate-grid-control.component';
import { AddLimitStaffUnitRateFormComponent } from './components/limit-staff-unit/add-limit-staff-unit-rate/add-limit-staff-unit-rate-form.component';
import { EmployeeNamesDeclensionsControlComponent } from './components/employers/names-declensions-control/employee-names-declensions-control.component';
import { EditEmployeeNamesDeclensionsComponent } from './components/employers/edit-employee-names-declensions/edit-employee-names-declensions.component';
import { CodeCaptchaComponent } from "src/app/components/code-captcha/code-captcha.component";
import { HierarchiStringsConflictsComponent } from "src/app/components/hierarchi-strings-conflicts/hierarchi-strings-conflicts.component";
import { TransferStaffUnitsFormComponent } from "./components/staff-units/transfer/transfer-staff-units.component";
import { ProductionCalendarDirectoryComponent } from './components/production-calendar/production-calendar-directory/production-calendar-directory.component';
import { ProductionCalendarDirectoryGridComponent } from './components/production-calendar/production-calendar-directory-grid/production-calendar-directory-grid.component';
import { KendoGridExpandedSaveSortDirective } from "src/app/directives/kendo-grid-expanded-save-sort.directive";
import { DismissStaffUnitFormComponent } from "./components/staff-units/dismiss/dismiss-staff-unit-form.component";
import {
  SubdivisionTreeview2Component
} from "../../../../src/app/components/subdivisions/subdivision-treeview2/subdivision-treeview2.component";
import {
  SubdivisionTreeviewWithCheckbox2Component
} from "../../../../src/app/components/subdivisions/subdivision-treeview-with-checkbox2/subdivision-treeview-with-checkbox2.component";
import {
  LimitStaffunitRatesReportSettingsComponent
} from "../../../../src/app/components/print-form-settings/limit-staffunit-rates-report-settings/limit-staffunit-rates-report-settings.component";
import { InputCapitalizeDirective } from '../../../../src/app/directives/input-capitalize.directive';
import {
  MilkOrderSettingsComponent
} from "../../../../src/app/components/print-form-settings/milk-order-settings/milk-order-settings.component";
import {HierarchiStringsComponent} from "../../../../src/app/components/hierarchi-strings/hierarchi-strings.component";
import {
  ReportPeriodSettingsComponent
} from "../../../../src/app/components/print-form-settings/report-period-settings/report-period-settings.component";
import { ICON_SETTINGS, IconModule } from '@progress/kendo-angular-icons';
import {
  PrintQuarterSettingsWithSubdivisionsAndStaffUnitTypesComponent
} from "../../../../src/app/components/print-form-settings/print-quarter-settings-with-subdivisions-and-staffunittypes/print-quarter-settings-with-subdivisions-and-staff-unit-types.component";
import {
  DeepCopyRequestInterceptor
} from "../../../../src/app/modules/auth/interceptors/deep-copy-request.interceptor";
import {
  CatchStaffUnitApprovedGraphTableInterceptor, CatchStaffUnitApprovedGraphTableInterceptor_ServiceBase
} from "../../../../src/app/modules/auth/interceptors/catch-staff-unit-approved-graph-table.interceptor";
import {
  CatchStaffUnitApprovedGraphTableInterceptorService
} from "../../../../src/app/services/webApi/webApi1/controllers/customs/catch-staff-unit-approved-graph-table-interceptor.service";
import {
  HierarchiStringsConflictsDialogService
} from "../../../../src/app/components/hierarchi-strings-conflicts/services/hierarchi-strings-conflicts-dialog.service";
import {LoadingIndicatorService} from "../../../../src/app/services/loading-indicator.service";
import {
  StandardServerResponseInterceptor
} from "../../../../src/app/modules/auth/interceptors/standard-server-response.interceptor";
import { GenderTextPipe } from '../../../../src/app/pipes/gender-text.pipe';
import { DateOnlyDirectoryEditComponent } from '../../../../src/app/components/directory-edit/controls/date-only/date-only-directory-edit-control.component';
import { DateOnlyFormControlToDateDirective } from '../../../../src/app/directives/date-only-form-control-to-date.directive';
import {
  KendoGridSelectByArrayDataSourceSelectionDirective
} from "../../../../src/app/directives/selections/kendo-grid-select-by-array-data-source-selection.directive";

/**
 * Функция настройки Jwt
 * @param authService
 */
export function jwtOptionsFactory(
  authService: AuthService,
  webApiAuthService: WebApiAuthService,
  appSettingsService: AppSettingsService
): JwtConfig {
  return {
    tokenGetter: (request) => {
      if (request.headers.has(JwtHelper.SkipTokenHeaderName)) {
        return "";
      }

      return lastValueFrom(authService.tokenOrUpdate$);
    },
    allowedDomains: [
      new URL(appSettingsService.webApiBaseUrl).host,
      new URL(appSettingsService.webApiAuthBaseUrl).host
    ],
    disallowedRoutes: [
      webApiAuthService.controllers.auth.actions.getToken.toString(),
      webApiAuthService.controllers.auth.actions.getTokenAndAuthUser.toString(),
      webApiAuthService.controllers.auth.actions.refreshToken.toString(),
      webApiAuthService.controllers.auth.actions.refreshTokenAndAuthUser.toString()
    ]
  }
}

/** Функция инициализирует приложение. Будет выполнятся до запуска и приложение будет ожидать ее выполнение */
function appInitializer(appSettingsService: AppSettingsService, environmentService: EnvironmentService) {
  return () => {
    return lastValueFrom(appSettingsService.initFromJson().pipe(tap(value => {
      DateHelper.serverDateSettings = {format: appSettingsService.webApiFormatSettings.date};
      environmentService.production = environment.production;
    })))
  }
}

@NgModule({
  declarations: [
    AppComponent,
    WorkspaceComponent,
    AlertComponent,
    WorkspaceErrorComponent,
    StafflistComponent,
    ReportDatesComponent,
    SubdivisionsListComponent,
    SubdivisionsTreelistComponent,
    DirectoriesListComponent,
    DirectoriesComponent,
    EmployeesDirectoryComponent,
    SubdivisionsDirectoryComponent,
    EmployeesGridComponent,
    AddEmployeeFormComponent,
    SubdivisionsDirectoryTreeListComponent,
    AddSubdivisionFormComponent,
    OccupationTypesDirectoryComponent,
    OccupationTypesDirectoryGridComponent,
    AddOccupationTypeFormComponent,
    OccupationsDirectoryComponent,
    AddOccupationFormComponent,
    OccupationsDirectoryGridComponent,
    CodesGridComponent,
    EditOccupationsCodesFormComponent,
    KendoGridExpandedDirective,
    KendoTreeListExpandedDirective,
    ReportPeriodsDirectoryComponent,
    AddReportPeriodFormComponent,
    ReportPeriodsDirectoryGridComponent,
    WorkModesDirectoryComponent,
    AddWorkModeFormComponent,
    WorkModesDirectoryGridComponent,
    AddPositionRateFormComponent,
    AddPositionRatesGridControlComponent,
    ChildInputPlaceholderDirective,
    KendoNumericExpandedDirective,
    KendoGridSelectByArrayDataSourceSelectionDirective,
    InternetConnectionComponent,
    PrintFormsComponent,
    PrintFormForDateSettingsComponent,
    MilkOrderSettingsComponent,
    PrintFormFromtillDatesSettingsComponent,
    PrintQuarterSettingsWithSubdivisionsComponent,
    SubdivisionTreeview2Component,
    SubdivisionTreeviewWithCheckbox2Component,
    StaffListReportSettingsComponent,
    PrintFormForDateSettingsWithSubdivisionTreeComponent,
    ImportFromExcelComponent,
    EditPassportDataComponent,
    EditPassportDataDialogComponent,
    EmployeeImportSettingsComponent,
    InputTrimDirective,
    DirectoryEditControlComponent,
    TextBoxDirectoryEditComponent,
    DateDirectoryEditComponent,
    DateOnlyDirectoryEditComponent,
    DropDownListDirectoryEditComponent,
    DropDownTreeDirectoryEditComponent,
    ComboboxDirectoryEditComponent,
    MaskedTextBoxDirectoryEditComponent,
    NumberDirectoryEditComponent,
    TextAreaDirectoryEditComponent,
    CheckboxDirectoryEditComponent,
    DisplayValidationErrorsComponent,
    CustomDirectoryEditComponent,
    DirectoryEditNotVersioningComponent,
    DirectoryEditVersioningComponent,
    PositionsGridComponent,
    AddPositionFormComponent,
    StaffUnitsGridComponent,
    AddStaffUnitFormComponent,
    LimitStaffUnitDirectoryComponent,
    AddLimitStaffUnitComponent,
    LimitStaffUnitsTreeListComponent,
    AddLimitStaffUnitRateGridControlComponent,
    AddLimitStaffUnitRateFormComponent,
    EmployeeNamesDeclensionsControlComponent,
    EditEmployeeNamesDeclensionsComponent,
    AddLimitStaffUnitRateFormComponent,
    HierarchiStringsConflictsComponent,
    HierarchiStringsComponent,
    CodeCaptchaComponent,
    TransferStaffUnitsFormComponent,
    ProductionCalendarDirectoryComponent,
    ProductionCalendarDirectoryGridComponent,
    KendoGridExpandedSaveSortDirective,
    DismissStaffUnitFormComponent,
    InputCapitalizeDirective,
    LimitStaffunitRatesReportSettingsComponent,
    ReportPeriodSettingsComponent,
    PrintQuarterSettingsWithSubdivisionsAndStaffUnitTypesComponent,
    GenderTextPipe,
    DateOnlyFormControlToDateDirective
  ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        AuthModule,
        FormsModule,
        ReactiveFormsModule,
        WindowModule,
        InputsModule,
        DateInputsModule,
        LayoutModule,
        LabelModule,
        DropDownsModule,
        JwtModule.forRoot({
            jwtOptionsProvider: {
                provide: JWT_OPTIONS,
                useFactory: jwtOptionsFactory,
                deps: [AuthService, WebApiAuthService, AppSettingsService]
            }
        }),
        ProgressBarModule,
        SharedComponentModule,
        DrawerModule,
        ButtonsModule,
        GridModule,
        PopupModule,
        MenuModule,
        ContextMenuModule,
        TimePickerModule,
        IntlModule,
        DialogModule,
        FloatingLabelModule,
        DropDownListModule,
        TextBoxModule,
        LabelModule,
        LayoutModule,
        TreeListModule,
        TooltipModule,
        DateInputsModule,
        NumericTextBoxModule,
        TreeViewModule,
        UploadsModule,
        ExcelModule,
        //SharedModule,
        IconModule
    ],
  providers: [
    { provide: LOCALE_ID, useValue: 'ru-RU' },
    { provide: MessageService, useClass: KendoMessageService },
    { provide: TracerServiceBase, useValue: new TracerService(new TraceStorageService()) },
    { provide: CustomStorageService, useClass: CustomStorageRealService, deps: [AppSettingsService, CryptService] },
    { provide: ErrorHandler, useClass: ErrorHandlerService, deps: [ServerLoggerService, EnvironmentService, TracerServiceBase] },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TraceInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DeepCopyRequestInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: IdenticalRequestInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DeepServerFormatInterceptor,
      multi: true
    },

    {provide: CatchStaffUnitApprovedGraphTableInterceptor_ServiceBase, useClass: CatchStaffUnitApprovedGraphTableInterceptorService},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CatchStaffUnitApprovedGraphTableInterceptor,
      multi: true,
      deps: [CatchStaffUnitApprovedGraphTableInterceptor_ServiceBase, DialogService, LoadingIndicatorService]
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: StandardServerResponseInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CatchErrorInterceptor,
      multi: true,
      deps: [AuthService, WorkSpaceErrorComponentService, ServerLoggerService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BlobInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      deps: [AppSettingsService, EnvironmentService],
      multi: true
    },
    { provide: WorkspaceServiceBase, useExisting: StafflistWorkspaceService },
    { provide: ICON_SETTINGS, useValue: { type: "font" } }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
