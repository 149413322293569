import {SupportedAction} from "../POCOs/versioning/SupportedAction";

/** Enum для {@link SupportedAction} */
export enum SupportedActionEnum{

  //#region stafflist

  /** 1, null, "Ввод должности", false, SupportedTableEnum.Stafflist_Occupations */
  stafflist_occupation_null = 1,
  /** 2, "name", "Переименование должности", false, SupportedTableEnum.Stafflist_Occupations */
  stafflist_occupation_name = 2,
  /** 3, "code", "Изменение кода должности", false, SupportedTableEnum.Stafflist_Occupations */
  stafflist_occupation_code = 3,

  /** 4, null, "Ввод сотрудника", false, SupportedTableEnum.Stafflist_Employees */
  stafflist_employee_null = 4,
  /** 5, "last_name", "Смена фамилии", false, SupportedTableEnum.Stafflist_Employees */
  stafflist_employee_last_name = 5,
  /** 6, "first_name", "Смена имени", false, SupportedTableEnum.Stafflist_Employees */
  stafflist_employee_first_name = 6,
  /** 7, "patronymic", "Смена отчества", false, SupportedTableEnum.Stafflist_Employees */
  stafflist_employee_patronymic = 7,
  /** 8, "code", "Изменение кода сотрудника", false, SupportedTableEnum.Stafflist_Employees */
  stafflist_employee_code = 8,

  /** 9, null, "Ввод подразделения", false, SupportedTableEnum.Stafflist_Subdivisions */
  stafflist_subdivision_null = 9,
  /** 10, "parent_id", "Вышестоящее подразделение", false, SupportedTableEnum.Stafflist_Subdivisions */
  stafflist_subdivision_parent_id = 10,
  /** 11, "short_name", "Переименование подразделения(Краткое наименование)", false, SupportedTableEnum.Stafflist_Subdivisions */
  stafflist_subdivision_short_name = 11,
  /** 12, "long_name", "Переименование подразделения(Полное наименование)", false, SupportedTableEnum.Stafflist_Subdivisions */
  stafflist_subdivision_long_name = 12,
  /** 13, "code", "Изменение кода подразделения", false, SupportedTableEnum.Stafflist_Subdivisions */
  stafflist_subdivision_code = 13,

  /** 15, null, "Ввод режима работы", false, SupportedTableEnum.Stafflist_WorkModes */
  stafflist_workMode_null = 15,
  /** 16, "name", "Переименование ражима работы", false, SupportedTableEnum.Stafflist_WorkModes */
  stafflist_workMode_name = 16,
  /** 17, "work_mode_type_id", "Смена типа режима работы", false, SupportedTableEnum.Stafflist_WorkModes */
  stafflist_workMode_work_mode_type_id = 17,
  /** 19, "working_days", "Рабочих дней в неделе", false, SupportedTableEnum.Stafflist_WorkModes */
  stafflist_workMode_working_days = 19,
  /** 21, "workday_hour_duration", "Часов в рабочем дне", false, SupportedTableEnum.Stafflist_WorkModes */
  stafflist_workMode_workday_hour_duration = 21,
  /** 23, "pre_holiday_hour_duration", "Часов в предпраздничном дне", false, SupportedTableEnum.Stafflist_WorkModes */
  stafflist_workMode_pre_holiday_hour_duration = 23,

  /** 26, null, "Ввод штатной позиции", false, SupportedTableEnum.Stafflist_Positions */
  stafflist_position_null = 26,
  /** 27, "report_period_id", "Смена периода отчетности", false, SupportedTableEnum.Stafflist_Positions */
  stafflist_position_report_period_id = 27,
  /** 28, "leader_flag", "Смена флага руководителя подразделения", false, SupportedTableEnum.Stafflist_Positions */
  stafflist_position_leader_flag = 28,
  /** 29, "dinner_hour_duration", "Изменение продолжительности обеда", false, SupportedTableEnum.Stafflist_Positions */
  stafflist_position_dinner_hour_duration = 29,
  /** 30, "subtract_lunch_time_from_working_hours_flag", "Смена флага - вычитывать обед", false, SupportedTableEnum.Stafflist_Positions */
  stafflist_position_subtract_lunch_time_from_working_hours_flag = 30,
  /** 31, "include_schedule_for_paid_service_flag", "Смена флага - включать в график приема по платным услугам", false, SupportedTableEnum.Stafflist_Positions */
  stafflist_position_include_schedule_for_paid_service_flag = 31,

  /** 33, null, "Ввод количества ставок", false, SupportedTableEnum.Stafflist_PositionRates */
  stafflist_positionRate_null = 33,
  /** 34, "rate", "Изменение количества ставок", false, SupportedTableEnum.Stafflist_PositionRates */
  stafflist_positionRate_rate = 34,

  /** 35, null, "Ввод штатной единицы", false, SupportedTableEnum.Stafflist_StaffUnits */
  stafflist_staffUnit_null = 35,
  /** 36, "financing_source_id", "Смена источника финансирования", false, SupportedTableEnum.Stafflist_StaffUnits */
  stafflist_staffUnit_financing_source_id = 36,
  /** 38, "rate", "Изменение размера ставки", false, SupportedTableEnum.Stafflist_StaffUnits */
  stafflist_staffUnit_rate = 38,
  /** 39, "percent", "Изменение размера выплаты", false, SupportedTableEnum.Stafflist_StaffUnits */
  stafflist_staffUnit_percent = 39,
  /** 79, "milk",	"Изменение выплаты компенсации за молоко", false, SupportedTableEnum.Stafflist_StaffUnits  */
  stafflist_staffUnit_milk = 79,


  /** 75, null, "Ввод лимита", false, SupportedTableEnum.Stafflist_LimitStaffUnits */
  stafflist_limitStaffUnits_null = 75,

  /** 76, null, "Ввод ставки", false, SupportedTableEnum.Stafflist_LimitStaffUnitRates */
  stafflist_limitStaffUnitRates_null = 76,
  /** 77, "value", "Изменение ставки", false, SupportedTableEnum.Stafflist_LimitStaffUnitRates */
  stafflist_limitStaffUnitRates_value = 77,

  //#endregion

  //#region pfhd

  /** 40, null, "Ввод КВР", false, SupportedTableEnum.Pfhd_Kvrs */
  pfhd_kvrs_null = 40,
  /** 41, "code", "Изменение кода КВР", false, SupportedTableEnum.Pfhd_Kvrs */
  pfhd_kvrs_code = 41,
  /** 42, "name", "Переименование КВР", false, SupportedTableEnum.Pfhd_Kvrs */
  pfhd_kvrs_name = 42,

  /** 45, null, "Ввод КОСГУ", false, SupportedTableEnum.Pfhd_Cosgus */
  pfhd_cosgu_null = 45,
  /** 46, "code", "Изменение кода КОСГУ", false, SupportedTableEnum.Pfhd_Cosgus */
  pfhd_cosgu_code = 46,
  /** 47, "name", "Переименование КОСГУ", false, SupportedTableEnum.Pfhd_Cosgus */
  pfhd_cosgu_name = 47,

  /** 48, null, "Ввод номенклатуры", false, SupportedTableEnum.Pfhd_Nomenclatures */
  pfhd_nomenclature_null = 48,
  /** 49, "name", "Переименование номенклатуры", false, SupportedTableEnum.Pfhd_Nomenclatures */
  pfhd_nomenclature_name = 49,
  /** 50, "code", "Изменение кода номенклатуры", false, SupportedTableEnum.Pfhd_Nomenclatures */
  pfhd_nomenclature_code = 50,
  /** 51, "short_name", "Переименование номенклатуры (Сокращённое наименование)", false, SupportedTableEnum.Pfhd_Nomenclatures */
  pfhd_nomenclature_short_name = 51,

  //#endregion

  //#region tarification

  /** 56, null, "Ввод справочника ПКГ и КУ", false, SupportedTableEnum.Tarification_PkgKus */
  tarification_pkgKus_null = 56,
  /** 62, "min_salary", "Изменение минимального должностного оклада по ПКГ", false, SupportedTableEnum.Tarification_PkgKus */
  tarification_pkgKus_min_salary = 62,
  /** 63, "min_salary_decree_id", "Изменение приказа минимального должностного оклада по ПКГ", false, SupportedTableEnum.Tarification_PkgKus */
  tarification_pkgKus_min_salary_decree_id = 63,

  /** 58, null, "Ввод Штатных единиц", false, SupportedTableEnum.Tarification_TarifStaffUnits */
  tarification_tarifStaffUnit_null = 58,
  /** 59, "pkg_ku_id", "Изменение ПКГ и КУ", false, SupportedTableEnum.Tarification_TarifStaffUnits */
  tarification_tarifStaffUnit_pkg_ku_id = 59,
  /** 60, "increasing_coefficient", "Изменение Повышающего коэффициента", false, SupportedTableEnum.Tarification_TarifStaffUnits */
  tarification_tarifStaffUnit_increasing_coefficient = 60,
  /** 61, "payments_dangerous_work", "Изменение Выплаты за работу с вредными/опасными условиями труда", false, SupportedTableEnum.Tarification_TarifStaffUnits */
  tarification_tarifStaffUnit_payments_dangerous_work = 61,
  /** 64, "working_condition_harm_id", "Изменение класса вредности", false, SupportedTableEnum.Tarification_TarifStaffUnits */
  tarification_tarifStaffUnit_working_condition_harm_id = 64,
  /** 65, "working_condition_harm_decree_id", "Изменение приказа устанавливающего класс вредности", false, SupportedTableEnum.Tarification_TarifStaffUnits */
  tarification_tarifStaffUnit_working_condition_harm_decree_id = 65,
  /** 66, "payments_secret_work", "Изменение выплаты за работу со сведениями, составляющими гос.тайну", false, SupportedTableEnum.Tarification_TarifStaffUnits */
  tarification_tarifStaffUnit_payments_secret_work = 66,
  /** 67, "payments_night_work", "Изменение выплаты за работу в ночное время", false, SupportedTableEnum.Tarification_TarifStaffUnits */
  tarification_tarifStaffUnit_payments_night_work = 67,
  /** 68, "harm_factor_id", "Изменение фактора вредности", false, SupportedTableEnum.Tarification_TarifStaffUnits */
  tarification_tarifStaffUnit_harm_factor_id = 68,

  /** 69, null, "Ввод сотрудника", false, SupportedTableEnum.Tarification_TarifEmployees */
  tarification_tarifEmployee_null = 69,
  /** 70, "payments_qualification_category_id", "Изменение выплаты за квалификационную категорию", false, SupportedTableEnum.Tarification_TarifEmployees */
  tarification_tarifEmployee_payments_qualification_category_id = 70,
  /** 78, "payments_young", "Изменение стимулирующей выплаты молодым специалистам", false, SupportedTableEnum.Tarification_TarifEmployees)] */
  tarification_tarifEmployee_payments_young = 78,
  /** 80, "payments_for_rank_id", "Выплаты за почетное звание", false, SupportedTableEnum.Tarification_TarifEmployees */
  tarification_tarifEmployee_payments_rank_id = 80,
  /** 81, "payments_for_degree_id", "Выплаты за ученую степень", false, SupportedTableEnum.Tarification_TarifEmployees */
  tarification_tarifEmployee_payments_degree_id = 81,
  /** 82, "payments_moskow_gov_grants", "Выплаты по грантам Правительства Москвы" */
  tarification_tarifEmployee_payments_moskow_gov_grants = 82,

  //#endregion

  //#region print

  /** 71, null, "Ввод шаблона", false, SupportedTableEnum.Print_UsedTemplates */
  print_usedTemplate_null = 71,
  /** 72, "template_id", "Смена шаблона", false, SupportedTableEnum.Print_UsedTemplates */
  print_usedTemplate_template_id = 72,

  /** 73, null, "Ввод значения", false, SupportedTableEnum.Print_AnchorValues */
  print_anchorValue_null = 73,
  /** 74, "value", "Смена значения", false, SupportedTableEnum.Print_AnchorValues */
  print_anchorValue_value = 74,

  //#endregion

}
